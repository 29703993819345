import React, { useEffect, useState } from "react";
import { useNavigate, NavLink, Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  useMediaQuery,
  Divider,
  Typography,
} from "@mui/material";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { BsEnvelope } from "react-icons/bs";

//import Diversity3Icon from "@mui/icons-material/Diversity3";
import MenuIcon from "@mui/icons-material/Menu";
import Div from "@jumbo/shared/Div";
import LOGO from "../../../assets/logon_top.png";
import { useSelector } from "react-redux";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AuthUserDropdown from "app/shared/widgets/AuthUserDropdown/AuthUserDropdown";
import { logoutAction } from "app/redux/actions/authAction";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { BsBriefcase } from "react-icons/bs";
import { BsCalendar2Event } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";

const AdminHeader = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user?.userData);
  const isMobile = useMediaQuery("(max-width:1300px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const location = useLocation()

  useEffect(() => {
    const accessToken = sessionStorage.getItem("token");
    if (!accessToken) {
      navigate("/");
      logoutAction();
    }
  }, []);
  // drawer
  const drawerContent = (
    <Div style={{ width: "280px", height: "100%" }}>
      <div className="admin_sidenav_container">
        <div className="sidebar_content">
          <div className="sidebar_content_top">
            <div className="admin_responsive_logo">
              <NavLink to="/manage-enquiry">
                <img src={LOGO} alt="LOGO" />
              </NavLink>
            </div>

            <div className="admin_responsive_links">
              <Divider component="li" />

              <ul>
                <li>
                  <NavLink to={"/manage-enquiry"}>
                    <DescriptionOutlinedIcon style={{ fontSize: "24px" }} />
                    Enquiry
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/manage-job"}>
                    <BsBriefcase style={{ fontSize: "24px" }} />
                    Jobs
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/job-application"}>
                    <BsEnvelope  style={{ fontSize: "24px" }} />
                    Job Application
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/manage-events"}>
                    <BsCalendar2Event  style={{ fontSize: "24px" }} />
                        Events
                  </NavLink>
                </li>
               
                <h6 className="devider_head">Settings</h6>
                <li>
                  <NavLink to={"/settings/manage-job-category"}>
                    Job Category
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/settings/manage-job-skill"}>Job Skill</NavLink>
                </li>
                <li>
                  <NavLink to={"/settings/manage-degree-level"}>
                    Degree Level
                    </NavLink>
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Div>
  );
  // function
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <AppBar position="static" className="admin_navbar">
        <Toolbar className="h-100" sx={{ height: "10%" }}>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className="d-flex items-center space-between w-100 h-full">
            <div className="d-flex  items-center h-full">
              <div className="d-flex items-center h-full">
                <NavLink to="/manage-enquiry">
                  <img height={60} width={100} src={LOGO} alt="LOGO" />
                </NavLink>
              </div>
              <div
                className={
                  isMobile === true
                    ? "admin_nav_menus d-none h-full"
                    : "admin_nav_menus h-full"
                }
              >
                <ul className="ml-0, pl-0 pr-0 h-full">
                  <li className="h-full">
                    <NavLink to={"/manage-enquiry"}>
                      <DescriptionOutlinedIcon style={{ fontSize: "24px" }} />{" "}
                      Enquiry
                    </NavLink>
                  </li>
                  <li className="h-full">
                    <NavLink to={"/manage-job"}>
                      <BsBriefcase style={{ fontSize: "24px" }} /> Jobs
                    </NavLink>
                  </li>
                  <li className="h-full">
                    <NavLink to={"/job-application"}>
                      <BsEnvelope  style={{ fontSize: "24px" }} />Job Application
                    </NavLink>
                  </li>
                  <li>
                  <NavLink to={"/manage-events"}>
                    <BsCalendar2Event  style={{ fontSize: "24px" }} />
                        Events
                  </NavLink>
                </li>
                  <li className={"dropdown_hover h-full "}>
                    <Link 
                    className = { location.pathname === '/settings/manage-job-category' ? "active" : location.pathname === '/settings/manage-job-skill' ? "active" : location.pathname === '/settings/manage-degree-level' ?  "active" : ""  } >
                      <IoSettingsOutline style={{ fontSize: "24px" }} />{" "}
                      Settings
                    </Link>
                    <div className="drpdownlinks">
                      <p className="m-0">
                        <NavLink to={"settings/manage-job-category"}>
                          Job Category
                        </NavLink>
                      </p>
                      <p className="m-0">
                        <NavLink to={"settings/manage-job-skill"}>
                          Job Skills
                        </NavLink>
                      </p>
                      <p className="m-0">
                        <NavLink to={"/settings/manage-degree-level"}>
                          Degree Level
                        </NavLink>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="d-flex items-center"
              style={{ whiteSpace: "nowrap", mr: 50 }}
            >
              <div className="mr-10">
                <Typography variant={"h5"} className="mb-0">
                  {userData?.user_email}
                </Typography>
              </div>
              <div className="mr-40">
                <AuthUserDropdown />
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          style={{ width: "400px" }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default AdminHeader;
