export const AppLogo = require('./app_logo.png')
export const NO_Recods = require('./no_records_found.png')
export const DOC = require('./doc.png');
export const AVATAR = require('./avartar.jpg');
export const EVENTGALLERY_1 = require('./evn-gallery-1.png')
export const EVENTGALLERY_2 = require('./evn-gallery-2.png')
export const EVENTGALLERY_3 = require('./evn-gallery-3.png')
export const EVENTGALLERY_4 = require('./evn-gallery-4.png')
export const EVENTGALLERY_5 = require('./evn-gallery-5.png')
export const EVENTGALLERY_6 = require('./evn-gallery-6.png')
export const EVENTGALLERY_7 = require('./evn-gallery-7.png')
export const EVENTGALLERY_8 = require('./evn-gallery-8.png')
export const NO_GALLARY = require('./no-gallary-img.png');
export const EVENT_BANNER = require('./eventdetail-banner.png');
