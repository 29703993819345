import React, { useState } from "react";
import {
  IconButton,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { loginAction } from "app/redux/actions/authAction";
import { config } from "../../../config/main";
import useApp from "app/hooks/useApp";
import jwtDecode from "jwt-decode";
import { AuthenticationContext } from "app/utils/constants/AuthContext";
import { Encrytion, NewdeCrypt } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import { AppLogo } from "app/assets";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login1 = () => {
  const appData = useApp();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthenticationContext);
  const [showPassword, setShowPassword] = useState(false);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    resetForm,
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    //  console.log("Form submitted:", values);
      setLoading(true);

      const EncryptEmail = await Encrytion(values.email);
      const Encryptpass = await Encrytion(values.password);
      const userData = {
        email: EncryptEmail.toString(),
        password: Encryptpass.toString(),
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      };

      try {
        await fetch(`${config.apiUrl}/admin/login`, options)
          .then((res) => res.json())
          .then((res) => {
          //  console.log(res, "response");
            if (res?.status === true && res?.accessToken) {
              setLoading(false);
              const decp = NewdeCrypt(res?.accessToken);
              const Refersh = NewdeCrypt(res?.refresToken);
              sessionStorage.setItem("auth", true);
              sessionStorage.setItem("portal", "admin");
              sessionStorage.setItem("token", decp);
              sessionStorage.setItem("refreshToken", Refersh);

              const getToken = sessionStorage.getItem("token");

              ToastServices.SucessToast(res?.message);
              const userData = jwtDecode(getToken);
              appData.setAppState({
                userData: userData,
              });
              loginAction(decp);
              setTimeout(() => {
                navigate("/manage-enquiry", { replace: true });
                login();
              }, 1000);
            } else if (res?.status === false) {
              ToastServices.ErroToast(res?.message);
              setLoading(false);
            } else if (res?.statusCode === 429) {
              setLoading(false);
              ToastServices.ErroToast("Something went wrong");
            } else if (res?.statusCode === 500) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            } else if (res?.statusCode === 404) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            }
          });
      } catch (err) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
      //  console.log(err, "Something went wrong");
      }
    },
  });

  //temp
  return (
    <Div className="current_user_session ">
      <div className=" flex-center-col ">
        <div className="current_user_session_card">
          <div style={{ textAlign: "center" }}>
            <img height={60} src={AppLogo} alt="nimalaan-admin-logo" />
          </div>
          <form
            className="h-100"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <div className="current_session_form justify-between h-100">
              <div className="w-100 ">
                <Typography
                  sx={{ textAlign: "center", mt: 1 }}
                  className="login_greeting"
                >
                  Welcome Back
                </Typography>
                <Div className="mt_12" sx={{ mt: 4 }}>
                  <Typography className="login_label">
                    Email Address <span className="required">*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    name="email"
                    placeholder="Enter Email Address"
                    type="email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                  />
                  {touched.email ? (
                    <small className="error_text">{errors.email}</small>
                  ) : (
                    ""
                  )}
                </Div>
                <Div className="mt_12" sx={{ mt: 3 }}>
                  <Typography className="login_label">
                    Password <span className="required">*</span>
                  </Typography>
                  <OutlinedInput
                    className="password_input"
                    name="password"
                    placeholder="Enter Password"
                    fullWidth={true}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    required={true}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ""}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.password ? (
                    <small className="error_text"> {errors.password} </small>
                  ) : (
                    ""
                  )}
                  <Typography
                    className="mt_0"
                    sx={{ mt: 2 }}
                    textAlign={"right"}
                  >
                    <Link
                      className="forget_link"
                      underline="none"
                      onClick={() => {
                        navigate("/forgot-password");
                      }}
                    >
                      Forgot Password ?
                    </Link>
                  </Typography>
                </Div>
              </div>
              <Div className="w-100">
                <LoadingButton
                  loading={loading}
                  className="login_btn"
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                >
                  Login Securely
                </LoadingButton>
              </Div>
            </div>
          </form>
        </div>
      </div>
    </Div>
  );
};

export default Login1;
