import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import {
  CardContent,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Pagination,
  Stack,
  Menu,
  MenuItem,
  Fade,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import debounce from "lodash/debounce";
import Swal from "sweetalert2";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { config } from "app/config/main";
import { logoutAction } from "app/redux/actions/authAction";
import { ToastServices } from "app/services/toastServices";
import LoaderComp from "app/components/loader/Loader";
import SkeletonTableLoader from "./SkeletonLoader";
import NoRecordsFound from "./NoRecordsFound";
import { Search, SearchIconWrapper, StyledInputBase } from "./style";


const ManageDegreeLevel = () => {

  // useSate 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const getToken = sessionStorage.getItem("token");
  const decrpttoken = Encrytion(getToken);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [sortType, setSortType] = useState("ASC");
  const [sortKey, setSortKey] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [showed, setShowed] = useState(false);
  const [error, setError]  = useState(false);
   const [degreeData, setdegreeData] = useState({
    search: "",
    limit: "10",
    offset: "1",
    sortByType: "",
    sortByKey: "",

  });
  // fetch api 
 const loadData = async () => {
   setLoading(true);
    const mergedData = { ...degreeData };
    const {search,limit,offset,sortByType,sortByKey,} = mergedData;
    let api = `${config.apiUrl}/degree-level/list?&offset=${offset}`;
    if (search) api += `&searchWith=${search}`;
    if (limit) api += `&limit=${limit}`;
    if (sortByType) api += `&sortByType=${sortByType}`;
    if (sortByKey) api += `&sortByKey=${sortByKey}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${decrpttoken}`,
      },
    };
    await fetch(api, options)
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setCount(res?.status ? res.count : 0);
        setTotalPage(res?.status ? res.totalPages : 0);
        setLoading(false);

        if (res.statusCode == 404) {
          setLoading(false);
          setError(true)
          
        }
        if (res.statusCode == 429) {
          setLoading(false);
          setError(true)
          
        }

        if (res.statusCode == 401) {
          navigate("/");
          logoutAction();
      
        }
      });
  };

//  pagination function 
  
const handlePaginationChange = (event, page) => {
  setPage(page);
  setdegreeData({ ...degreeData, offset: page });
};

const handleItemsPerPageChange = (event) => {
  setItemsPerPage(event.target.value);
  setPage(1);
  setdegreeData({ ...degreeData, limit: event.target.value, offset: 1 });
};


// search call 
// Debounce the handleSearchChange function

  const debouncedSearch = debounce((input) => {
    setLoading(true);
    const lowerCaseInput = input.toLowerCase();
    setdegreeData({ ...degreeData, search: input });
    setLoading(false);
  }, 3000);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    let input = event.target.value.toLowerCase();
    debouncedSearch(input);
  };

  const handleClearSearch = () => {
    if (searchInput != "") {
      setSearchInput("");
      // Clear the input value in the DOM
      const inputElement = document.querySelector(".search input");
      if (inputElement) {
        inputElement.value = "";
      }
      setdegreeData({ ...degreeData, search: "" });
    }
  };

  //  Sorting call 

  const handleSort = (column) => {
   const newSorttype =
      sortKey === column && sortType === "ASC" ? "DESC" : "ASC";
    setSortType(newSorttype);
    setSortKey(column);
    
    setdegreeData({
      ...degreeData,
      sortByType: newSorttype,
      sortByKey: column,
      offset: 1,
    });
  };
 
  // menu 

  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(i);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };


  // change status 

  const handleChangeStatus = async (id, status) => {
    setAnchorEl(null);
    setSelectedIndex(null);
    const userData = {
      id : id,
      status:status === 1 ? 0 : 1
    }

    const options = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${decrpttoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    }

    try {
      await fetch(`${config.apiUrl}/degree-level/status`, options)
        .then((res) => res.json())
        .then((res) => {
         
          if (res?.status === true) {
            setLoading(false);
            ToastServices.SucessToast(res?.message);
            setdegreeData({ ...degreeData,});
           
          } else if (res?.status === false) {
            ToastServices.ErroToast(res?.message);
            setLoading(false);
          } else if (res?.statusCode === 429) {
            setLoading(false);
            ToastServices.ErroToast("Something went wrong");
          } else if (res?.statusCode === 500) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          } else if (res?.statusCode === 404) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          }
        });
    } catch (err) {
      setLoading(false);
      ToastServices.ErroToast("Something went wrong");
    }
  }

  // Links 

  const handleCreate = () => {
    navigate("/settings/manage-degree-level/create");
  };
  
  const handleUpdate = (id) => {
    navigate(`/settings/manage-degree-level/update/${id}`);
  };

  const handleDelete = async (id, ) => {
    setAnchorEl(null);
    setSelectedIndex(null);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
         deleteAction(id)
      }
    });
  }

  const deleteAction = async (id) =>{
    const userData = {
      id:id
    }
    const options = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${decrpttoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    }
    try {
      await fetch(`${config.apiUrl}/degree-level/delete/${id}`, options)
        .then((res) => res.json())
        .then((res) => {
         
          if (res?.status === true) {
            setLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Your item has been deleted.",
              icon: "success"
            }).then(()=>{
              loadData();
              
            })
          } else if (res?.status === false) {
            ToastServices.ErroToast(res?.message);
            setLoading(false);
          } else if (res?.statusCode === 429) {
            setLoading(false);
            ToastServices.ErroToast("Something went wrong");
          } else if (res?.statusCode === 500) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          } else if (res?.statusCode === 404) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          }
        });
    } catch (err) {
      setLoading(false);
      ToastServices.ErroToast("Something went wrong");
    }
  }

// UseEffect 

  useEffect(() => {
    loadData();
  }, [degreeData]);

 return (
    <Fragment>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >{`Degree Level (${  data?.length ? data?.length : "0"
            })`}</Typography>
          </div>

          <div className="">
          
            <Search className="search">
              <SearchIconWrapper>
                <SearchIcon className="search-icon" />
              </SearchIconWrapper>

              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
                endAdornment={
                  searchInput && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon fontSize="12" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Search>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end">
          <div className="ml-20 ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleCreate}
            >
              <AddIcon className="mr-5" />
              Create
            </Button>
          </div>
        </div>
      </Div>
      <Card className="responsive_card">
        <CardContent>
          <Div>
            <Table className="common_table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "name"}
                      direction={sortType}
                      onClick={() => handleSort("name")}>
                     Degree
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <>
                    <LoaderComp />
                   
                  </>
                ) 
              }
                
                {
                  data?.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{item?.degreeName}</TableCell>
                        <TableCell>
                          {item?.status === 1 ? (
                            <p className="p-active status">Active</p>
                          ) : (
                            <p className="p-inactive status" >Inactive</p>
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                          className="p-0"
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                        <Menu
                          className="custom_popover"
                          id="fade-menu"
                          MenuListProps={{
                            "aria-labelledby": "fade-button",
                          }}
                          open={selectedIndex === i}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={() => handleUpdate(item?.id)}>
                            Update
                          </MenuItem>
                          <MenuItem onClick={(e)=> handleChangeStatus(item?.id, item?.status)}>
                            {item?.status === 1
                              ? "Mark as Inactive"
                              : "Mark as Active"}
                          </MenuItem>
                          <MenuItem
                           onClick={(e)=> handleDelete(item?.id)}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableRow>
                    );
                  })
                }
                {data?.length === 0 ||  error ? ( <NoRecordsFound tablecell={3} />) : ""}
              </TableBody>
            </Table>
            {
              count > 0 && (
                <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <div className="count_page">
                  <span className="mr-10">Items per page :</span>
                  <select
                    className="pagination_selectbox"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="30">50</option>
                  </select>
                </div>
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handlePaginationChange}
                  />
                </Stack>
              </div>
             )
            }
          </Div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default ManageDegreeLevel;
