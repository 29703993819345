import { CircularProgress } from '@mui/material'
import React from 'react'

const LoaderComp = () => {
  return (
    <div className='loader_layout'>
        <div className='loadr_box'>
        <CircularProgress />

        </div>
    </div>
  )
}

export default LoaderComp