import React from "react";
import { getModulePermission } from "app/utils/menuHelpers";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

let portal = sessionStorage.getItem("portal");
let USER_MENU = [];

portal = null ?? "admin";

if (portal === "user") {
  await getModulePermission().then((res) => {
    USER_MENU = res;
  });
}

const ADMIN_MENU = [
  {
    uri: "/manage-enquiry",
    label: "Enquiry",
    type: "nav-item",
    icon: <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/manage-job",
    label: "Jobs",
    type: "nav-item",
    icon: <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/manage-job",
    label: "Appliyed Jobs",
    type: "nav-item",
    icon: <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />,
  },
];

const menus = [
  {
    label: "Settings",
    type: "section",
    children: portal === "admin" ? ADMIN_MENU : USER_MENU,
  },
];

export default menus;
