import { TableCell, TableRow, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import NO_RECORD_FOUND from "../../../assets/no_records_found.png";

const NoRecordsFound = ({ tablecell }) => {
  return (
    <>
      <TableRow align="center">
        <TableCell align="center" colSpan={tablecell}>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              placeItems: "center",
              height: "100vh",
            }}
          >
            <img
              width="200px"
              height="200px"
              src={NO_RECORD_FOUND}
              alt="your-image-alt-text"
            />
            <Typography
              variant={"h2"}
              className={"text-center no_records"}
              sx={{ alignItems: "center" }}
            >
              {" "}
              No Records Found{" "}
            </Typography>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default NoRecordsFound;
