import JobCreate from "./JobCreate";


const CreateJob = () => {
 
  return (
    <>
       <JobCreate />
    
    </>

  );
};

export default CreateJob;
