import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  Link,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";
import { useFormik } from "formik";
import PasswordTooltip from "@jumbo/components/PasswordInfoTooltip/PasswordTooltip";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import { config } from "../../../config/main";
import { AppLogo } from "app/assets";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),

  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [conshowPassword, setConshowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const urlString = window.location.href;
  const url = new URL(urlString);
  const pathname = url.pathname;
  const parts = pathname.split("/");
  const gettoken = parts[parts.length - 1];
  // console.log(gettoken);

  useEffect(() => {}, [gettoken]);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    resetForm,
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("Form submitted:", values);

      const EncryptPassword = await Encrytion(values.password);
      const EncryptConfirmPassword = await Encrytion(values.confirmpassword);

      const userData = {
        password: EncryptPassword.toString(),
        confirmpassword: EncryptConfirmPassword.toString(),
        token: gettoken,
      };

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      };
      try {
        await fetch(`${config.apiUrl}/admin/reset-token`, options)
          .then((res) => res.json())
          .then((res) => {
            console.log(res, "response");
            if (res?.status === true) {
              setLoading(false);
              ToastServices.SucessToast(res?.message);
              resetForm();
              navigate("/success-message", { replace: true });
            } else if (res?.status === false) {
              ToastServices.ErroToast(res?.message);
              setLoading(false);
            } else if (res?.statusCode === 429) {
              setLoading(false);
              ToastServices.ErroToast("Something went wrong");
            } else if (res?.statusCode === 500) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            } else if (res?.statusCode === 404) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            }
          });
      } catch (err) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
        console.log(err, "Something went wrong");
      }
    },
  });

  return (
    <Div className="current_user_session ">
      <div className="flex-center-col">
        <div className="current_user_session_card">
          <div style={{ textAlign: "center" }}>
            <img height={60} src={AppLogo} alt="nimalaan-admin-logo" />
          </div>
          <form
            className="h-100"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <div className="text-center">
              <h2 className="admin_greetings" style={{ marginBottom: 0 }}>
                Set New Password
              </h2>
            </div>
            <div className="mt-20">
              <Div sx={{ mt: 2 }}>
                <Typography className="login_label d-flex items-center g-10">
                  <span>
                    Password <span className="required">*</span>
                  </span>{" "}
                  &nbsp; <PasswordTooltip />
                </Typography>
                <OutlinedInput
                  className="password_input "
                  name="password"
                  placeholder="Enter Password"
                  fullWidth={true}
                  error={touched.password && Boolean(errors.password)}
                  type={showPassword ? "text" : "password"}
                  required={true}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.password ? (
                  <small className="error_text">{errors.password}</small>
                ) : (
                  ""
                )}
              </Div>
              <Div sx={{ mt: 3 }}>
                <Typography className="login_label d-flex items-center g-10">
                  <span>
                    Confirm Password <span className="required">*</span>
                  </span>{" "}
                  &nbsp; <PasswordTooltip />
                </Typography>
                <OutlinedInput
                  className="password_input"
                  name="confirmpassword"
                  placeholder="Enter Confirm Password"
                  fullWidth={true}
                  error={
                    touched.confirmpassword && Boolean(errors.confirmpassword)
                  }
                  type={conshowPassword ? "text" : "password"}
                  required={true}
                  value={values.confirmpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setConshowPassword(
                            (conshowPassword) => !conshowPassword
                          )
                        }
                        edge="end"
                      >
                        {conshowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.confirmpassword ? (
                  <small className="error_text">{errors.confirmpassword}</small>
                ) : (
                  ""
                )}
              </Div>
              <LoadingButton
                className="login_btn"
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                loading={loading}
              >
                Set New Password
              </LoadingButton>
              <Typography textAlign={"center"}>
                <Link className="back_to_login" underline="none" href="/">
                  Back to Login
                </Link>
              </Typography>
            </div>
          </form>
        </div>
      </div>
    </Div>
  );
};

export default ResetPassword;
