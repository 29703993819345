// AuthenticationContext.js
import { createContext, useEffect, useState } from 'react';

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {

  const verifyJWTFromSessionStorage = () => {
 
    const jwt = sessionStorage.getItem('token');
    return jwt ? true : false;
  };

  const [isAuthenticated, setIsAuthenticated] = useState(verifyJWTFromSessionStorage());


  useEffect(()=>{
    setIsAuthenticated(verifyJWTFromSessionStorage())

    return () =>{
      setIsAuthenticated(false)
    }
  },[])


 



  const login = () => {
    setIsAuthenticated(true);
  //  console.log(isAuthenticated , "isAuthenticated")
  };

  const selogout = () => {
    setIsAuthenticated(false);
  };

  

return (
    <AuthenticationContext.Provider value={{ isAuthenticated , login, selogout  }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
