import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";

import { SucessGifLogin } from "app/components/SucessGif/SucessGif";
import { AppLogo } from "app/assets";

const SuccessMessage = () => {
  const navigate = useNavigate();

  function handleClick(event) {
    event.preventDefault();
    navigate("/", { replace: true });
  }

  return (
    <Div className="current_user_session ">
      <div className="flex-center-col">
        <div className="current_user_session_card">
          <div className="current_session_form justify-between h-100">
            <div style={{ textAlign: "center" }}>
              <img height={60} src={AppLogo} alt="nimalaan-admin-logo" />
            </div>
            <div className="w-100 ">
              <Div sx={{ mt: 4, textAlign: "center" }}>
                <div className="login_gif">
                  <SucessGifLogin />
                </div>
                <p className="sucess_greeting text-center">
                  You have successfully reset your password. Please use your new
                  password when logging in.
                </p>
              </Div>
            </div>
            <Div className="w-100">
              <LoadingButton
                className="login_btn"
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                onClick={handleClick}
              >
                Go to Login
              </LoadingButton>
            </Div>
          </div>
        </div>
      </div>
    </Div>
  );
};

export default SuccessMessage;
