import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Div from '@jumbo/shared/Div';
import axios from 'axios';
import moment from 'moment';
import { DateSvg, EventLocationSvg } from 'app/assets/svg';
import { ToastServices } from 'app/services/toastServices';
import { Encrytion } from 'app/utils/EncryptionHelpers';
import { config } from "app/config/main";

const ViewEvents = () => {
    
    const { id } = useParams();
    const [pageloader, setPageLoader] = useState(false);
    const [viewdata, setViewDate] = useState({});
    const getToken = sessionStorage.getItem("token");
    const EncryptToken = Encrytion(getToken);
    const navigate = useNavigate();
    const handleBacktoList = () => {
        navigate("/manage-events");
    };

    const getView = async () => {
        setPageLoader(true);
        const headers = {
          Authorization: `Bearer ${EncryptToken}`,
          "Content-Type": "application/json",
        };
    
        try {
          const response = await axios.get(`${config.apiUrl}/events/view/${id}`, {
            headers,
          });
    
          if (!response.status == true) {
            ToastServices.ErroToast("Something went wrong");
            setPageLoader(false);
          }
    
          setViewDate(response?.data?.data[0]);
          setPageLoader(false);
        } catch (e) {
        }
      };

      useEffect(() => {
        getView();
      }, []);

return (
    <div>
         <Box>
                <Div className="div_container d-flex justify-content space-between">
                    <div className="d-flex justify-content-start flex-start">
                        <div className="mt-10">
                            <Typography
                                className="heading"
                                variant={"h2"}
                                mb={5}
                                fontWeight={600}
                            >{`View Event`}</Typography>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end flex-end mr-20">
                        <div className="">
                            <Button
                                variant="outlined"
                                className="outlined_btn"
                                onClick={handleBacktoList}
                            >
                                <ChevronLeftOutlinedIcon sx={{ mb: 0.2, mr: 0.5 }} />
                                Back
                            </Button>
                        </div>
                    </div>
                </Div>
                <Grid container xs={12} spacing={2}>
                    <Grid item lg={12} spacing={2}>
                        <Card>
                        <div className='event-content'>
                        <div className='event_banner'>
                            <img src={viewdata?.bannerImageUrl} alt={viewdata?.eventTitle} />
                            </div>
                    <h1 className='event_title'>{viewdata?.eventTitle}</h1>
                    <div className='event_timing'>
                        <div className='event_location'>
                            <DateSvg />
                            <p>{moment(viewdata?.eventDate).format('dddd, DD MMMM, YYYY')}</p>

                        </div>
                        <div className='event_location'>
                            <EventLocationSvg />
                            <p>{viewdata?.eventLocation}</p>
                        </div>

                    </div>
                    <div className='event-descrp'>
                       <div dangerouslySetInnerHTML={{ __html: viewdata?.eventDescription }} />
                    </div>
                </div>
                        </Card>
                     
                    </Grid>
                </Grid>
            </Box>
    </div>
  )
}

export default ViewEvents