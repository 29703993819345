import { TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SkeletonTableLoader = ({ tablecell, row }) => {
  const rows = Array.from({ length: row }, (_, index) => (
    <TableRow key={index}>
      {Array.from({ length: tablecell }, (_, cellIndex) => (
        <TableCell key={cellIndex}>
          {" "}
          <Skeleton variant="text" sx={{ fontSize: "1.5rem", width:"140px" }} />{" "}
        </TableCell>
      ))}
    </TableRow>
  ));

  return <>{rows}</>;
};
export default SkeletonTableLoader;
