import React, { useState } from 'react';
import { Chip, Box, InputBase } from '@mui/material';
import { Close } from '@mui/icons-material';

function MultiValueInput({ values, onAdd, onDelete, error, touched }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      if (!values.includes(inputValue.trim())) {
        onAdd(inputValue.trim());
        setInputValue('');
      }
    } else if (event.key === 'Backspace' && inputValue === '') {
      // Remove the last chip when backspace is pressed and input is empty
      onDelete(values.length - 1);
    }
  };

  const handleDelete = (valueToDelete) => () => {
    onDelete(valueToDelete);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          border: `1px solid ${error && touched ? 'red' : '#ccc'}`, // Adjust border color based on error state and touched
          borderRadius: '4px',
          padding: '6px 8px',
          '&:focus-within': {
            borderColor: '#3f51b5',
          },
        }}
      >
        {values?.map((value, index) => (
          <Chip
            key={index}
            label={value}
            onDelete={handleDelete(value)}
            sx={{ margin: '2px' }}
          />
        ))}
        <InputBase
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="Enter Job Tag"
          className="job_tag"
          sx={{
            flex: 1,
            border: 'none',
            outline: 'none',
            padding: '10.5px',
            minWidth: '100px',
            color: 'inherit',
            background: 'inherit',
          }}
        />
      </Box>
    </Box>
  );
}

export default React.memo(MultiValueInput);
