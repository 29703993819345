
import React, { Fragment, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import debounce from "lodash/debounce";
import Div from "@jumbo/shared/Div";
import moment from "moment";

import {
  Box,
  Button,
  Card,
  CardContent,
  Drawer,
  Fade,
  IconButton,
  InputAdornment,
  Menu,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  FormHelperText,
  Autocomplete,FormControl, Select, MenuItem
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  ClearIcon,
  LocalizationProvider,
  DatePicker,
} from "@mui/x-date-pickers";
import FilterListIcon from "@mui/icons-material/FilterList";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButton from "@mui/lab/LoadingButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ToastServices } from "app/services/toastServices";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { AuthenticationContext } from "app/utils/constants/AuthContext";
import { logoutAction } from "app/redux/actions/authAction";
import { config } from "app/config/main";
import LoaderComp from "app/components/loader/Loader";

import SkeletonTableLoader from "./SkeletonLoader";
import NoRecordsFound from "./NoRecordsFound";
import { Search, SearchIconWrapper, StyledInputBase } from "./style";


const AppliedJobList = () => {
  const { isAuthenticated } = useContext(AuthenticationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateStatusOpen, setupdateStatusOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const getToken = sessionStorage.getItem("token");
  const decrpttoken = Encrytion(getToken);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [jobtitle, SetjobTitle] = useState([]);
  const [sortType, setSortType] = useState("ASC");
  const [sortKey, setSortKey] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [dateopen, setDateOpen] = useState(false);
  const [todateOpen, setTodateOpen] = useState(false);
  const [statusCode, setStatusCode] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [error, setError]  = useState(false);
  const [arrayStatus, setArrayStatus] = useState([])
  const [applicationData, setapplicationData] = useState({
    job: "",
    status: "",
    toDate: "",
    fromDate: "",
    search: "",
    limit: "10",
    offset: "1",
    sortByType: "",
    sortByKey: "",
  });

  // initial api

  const loadData = async () => {
    setLoading(true);
    const mergedData = { ...applicationData };
    const {
      job,
      status,
      toDate,
      fromDate,
      search,
      limit,
      offset,
      sortByType,
      sortByKey,
    } = mergedData;
    let api = `${config.apiUrl}/jobs/applied/list?`;

    if (job) api += `&job=${job}`;
    if (status) api += `&status=[${status}]`;
    if (toDate) api += `&toDate=${toDate}`;
    if (fromDate) api += `&fromDate=${fromDate}`;
    if (search) api += `&searchWith=${search}`;
    if (limit) api += `&limit=${limit}`;
    if (offset) api += `&offset=${offset}`;
    if (sortByType) api += `&sortByType=${sortByType}`;
    if (sortByKey) api += `&sortByKey=${sortByKey}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${decrpttoken}`,
      },
    };

    await fetch(api, options)
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setCount(res?.status ? res.count : 0);
        // setPage(((res?.status)?res.totalPages:0))
        setTotalPage(res?.status ? res.totalPages : 0);
        setLoading(false);

        if (res.statusCode == 404) {
          setLoading(false);
          setError(true)
          
        }

        if (res.statusCode === 401) {
          navigate("/");
          logoutAction();
          setStatusCode(true);
        }
      });
  };

  const loadJobTitle = async () => {
    const options = {
      method: "GET",
    };
    await fetch(`${config.apiUrl}/jobs/raw-jobs/list`, options)
      .then((res) => res.json())
      .then((res) => {
        SetjobTitle(res.data);
      });
  };

  // menu
  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(i);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  // sorting

  const handleSort = (column) => {
    const newSorttype =
      sortKey === column && sortType === "ASC" ? "DESC" : "ASC";
    setSortType(newSorttype);
    setSortKey(column);
    setapplicationData({
      ...applicationData,
      sortByType: newSorttype,
      sortByKey: column,
      // offset: 1,
    });
  };

  const handlePaginationChange = (event, page) => {
    setPage(page);
    setapplicationData({ ...applicationData, offset: page });
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
    setapplicationData({ ...applicationData, limit: event.target.value, offset: 1 });
  };

  // search

  const debouncedSearch = debounce((input) => {
    setLoading(true);
    const lowerCaseInput = input?.toLowerCase();
    setapplicationData({ ...applicationData, search: input });

    setLoading(false);
  }, 2600);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);

    let input = event.target.value?.toLowerCase();

    debouncedSearch(input);
  };

  const handleClearSearch = () => {
    if (searchInput != "") {
      setSearchInput("");
      // Clear the input value in the DOM
      const inputElement = document.querySelector(".search input");
      if (inputElement) {
        inputElement.value = "";
      }
      setapplicationData({ ...applicationData, search: "" });
    }
  };

  // fillter

  function handleFilterToggle() {
    setFilterOpen(!filterOpen);
  }
  function handleFilterClose() {
    setFilterOpen(false);
  }
  const handleClear = () => {
    const initialValues = formik.initialValues;
    const currentValues = formik.values;

    const shouldReset = Object.keys(currentValues).some(
      (key) => currentValues[key] !== initialValues[key]
    );

    if (shouldReset) {
      formik.resetForm();
      handleReset();
      setSearchInput("");

      const inputElement = document.querySelector(".search input");
      if (inputElement) {
        inputElement.value = "";
      }
    }
    handleFilterClose();
    //  ToastServices.SucessToast("Data reset successfully")
  };
  const handleFilter = () => {
    handleFilterToggle();
    handleClose();
  };

  const validationSchema = yup.object().shape({
    fromDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) {
          return null;
        }
        const date = moment(originalValue, moment.ISO_8601, true);
        return date.isValid() ? date.toDate() : new Date(NaN);
      })
      .typeError("From Date must be a valid date")
      .when("toDate", {
        is: (toDate) => toDate && !isNaN(toDate.getTime()),
        then: yup
          .date()
          .required("From Date is required when To Date is selected"),
      })
      .test(
        "fromDate-before-toDate",
        "From Date cannot be after To Date",
        function (fromDate) {
          const { toDate } = this.parent;
          return (
            !fromDate ||
            !toDate ||
            moment(fromDate).isSameOrBefore(toDate, "day")
          );
        }
      ),
    toDate: yup
      .date()
      .nullable()
      .test(
        "toDate-after-fromDate",
        "To date cannot be before From Date",
        function (toDate) {
          const { fromDate } = this.parent;
          return (
            !toDate ||
            !fromDate ||
            moment(toDate).isSameOrAfter(fromDate, "day")
          );
        }
      ),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      job: "",
      status: "",
      toDate: null,
      fromDate: null,
    },

    onSubmit: async (values) => {
      handleSubmit(values);
      handleFilterClose();
    },
  });

  const handleSubmit = (values) => {
    setisLoading(true);

    const userData = {
      job: values.job,
      status: values?.status ? values?.status : "",
      toDate: !values?.toDate
        ? ""
        : moment(values?.toDate).format("YYYY-MM-DD"),
      fromDate: !values?.fromDate
        ? ""
        : moment(values?.fromDate).format("YYYY-MM-DD"),
    };

    const hasNonEmptyValues = Object.values(userData).some(
      (value) => value !== ""
    );

    setFilterData(userData);

    if (hasNonEmptyValues) {
      setapplicationData({ ...applicationData, ...userData });
      ToastServices.SucessToast("Data filtered successfully");
    }

    setisLoading(false);
  };

  // reset
  const handleReset = () => {
    formik.resetForm();
    setapplicationData({
      job: "",
      status: "",
      toDate: "",
      fromDate: "",
      searchWith: "",
      limit: "",
      offset: "",
      sortByType: "",
      sortByKey: "",
    });
    setSearchInput("");

    const inputElement = document.querySelector(".search input");
    if (inputElement) {
      inputElement.value = "";
    }
    ToastServices.SucessToast("Filter reset successfully");
  };

  useEffect(() => {
    const fetchDataWithDelay = async () => {
      await loadJobTitle();
    };
    if (isAuthenticated) {
      fetchDataWithDelay();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      loadData();
    }
    if (statusCode === true) {
      navigate("/");
      logoutAction();
    }
  }, [applicationData]);

  function handleFilterToggle() {
    setFilterOpen(!filterOpen);
  }

  // status update side nav

  function handleUpdateStatusToggle() {
    setupdateStatusOpen(!updateStatusOpen);
  }

  function handleUpdateStatusClose() {
    setupdateStatusOpen(false);
    Statusformik.setFieldValue("status", "")
   
  }

  const handleUpdateStatus = (id, itemstatus, i) => {
    handleUpdateStatusToggle();
    handleClose();
    setSelectedItemId(id);

    const filteredStatusArray = StatusArray.filter(status => status?.value !== itemstatus);
    setArrayStatus(filteredStatusArray)

  };

  const StatusArray = [
    { value : 2, viewValue : "Under Review" },
    { value : 3, viewValue : "Interviewed" },
    { value : 4, viewValue : "Offered" },
    { value : 5, viewValue : "Rejected" },
 
  ]





  const Statusformik = useFormik({
    initialValues: {
      status: "",
    },

    onSubmit: async (values) => {
      handleStatusSubmit(values);
      handleUpdateStatusClose();
    },
  });

  const handleStatusSubmit = async (values) => {
    setisLoading(true);

    const userData = {
      id: selectedItemId,
      status: values?.status ? values?.status : "",
    };

    const options = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${decrpttoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    };

    const hasNonEmptyValues = Object.values(userData).some(
      (value) => value !== ""
    );

    setFilterData(userData);

    if (hasNonEmptyValues) {
      try {
        await fetch(`${config.apiUrl}/jobs/appliedjob/updatestatus`, options)
          .then((res) => res.json())
          .then((res) => {
            if (res?.status === true) {
              setLoading(false);
              ToastServices.SucessToast(res?.message);
              loadData();
            } else if (res?.status === false) {
              ToastServices.ErroToast(res?.message);
              setLoading(false);
            } else if (res?.statusCode === 429) {
              setLoading(false);
              ToastServices.ErroToast("Something went wrong");
            } else if (res?.statusCode === 500) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            } else if (res?.statusCode === 404) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            }
          });
      } catch (err) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
      }
    }
  };

  const handleJobChange = (event, value) => {
    formik.setFieldValue('job', value?.id || ''); 
    formik.setFieldTouched('job', true, false); 
  };



  const handleView = (id) => {
    navigate(`/job-application/view/${id}`);
  };

  return (
    <Fragment>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >Job Applications ({data?.length})</Typography>
          </div>

          <div className="">
            <Search className="search">
              <SearchIconWrapper>
                <SearchIcon className="search-icon" />
              </SearchIconWrapper>

              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
                endAdornment={
                  searchInput && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon fontSize="12" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Search>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end">
          <div className="ml-20 ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleFilter}
            >
              <FilterListIcon className="mr-5" />
              Filter
            </Button>
          </div>
          <div className="ml-20  ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleReset}
            >
              <CachedIcon className="mr-5" />
              Reset
            </Button>
          </div>
        </div>
      </Div>
      <Card className="responsive_card">
        <CardContent>
          <Div>
            <Table className="common_table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "createdAt"}
                      direction={sortType}
                      onClick={() => handleSort("createdAt")}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "jobName"}
                      direction={sortType}
                      onClick={() => handleSort("jobName")}
                    >
                      Job Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "fullName"}
                      direction={sortType}
                      onClick={() => handleSort("fullName")}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "mobileNo"}
                      direction={sortType}
                      onClick={() => handleSort("mobileNo")}
                    >
                      Mobile Number
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "email"}
                      direction={sortType}
                      onClick={() => handleSort("email")}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>Status</TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <>
                    <LoaderComp />
                   
                  </>
                )  
              }
                
                {
                  data?.map((item, i) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {moment(item?.createdAt).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          <span
                            className="link"
                            onClick={() => handleView(item?.id)}
                          >
                            {item?.job?.jobTitle.length > 25 ? (
                              <>
                                {item?.job?.jobTitle.slice(0, 25)}
                                <Tooltip title={item?.job?.jobTitle}>
                                  <span className="cursor">{`...`}</span>
                                </Tooltip>
                              </>
                            ) : (
                              <>{item?.job?.jobTitle}</>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>{item?.fullName}</TableCell>
                        <TableCell>{item?.mobileNo}</TableCell>
                        <TableCell>
                          {item?.email.length > 25 ? (
                            <>
                              {item?.email.slice(0, 25)}
                              <Tooltip title={item?.email}>
                                <span className="cursor">{`...`}</span>
                              </Tooltip>
                            </>
                          ) : (
                            <>{item?.email}</>
                          )}
                        </TableCell>

                        <TableCell>
                          <p
                            className={
                              item?.status == 1
                                ? "status new"
                                : item?.status == 2
                                ? "status under_review"
                                : item?.status == 3
                                ? "status interviewed"
                                : item?.status == 4
                                ? "status offered"
                                : item?.status == 5
                                ? "status rejected"
                                : ""
                            }
                          >
                            {item?.status == 1
                              ? "New"
                              : item?.status == 2
                              ? "Under Review"
                              : item?.status == 3
                              ? "Interviewed"
                              : item?.status == 4
                              ? " Offered"
                              : item?.status == 5
                              ? " Rejected"
                              : ""}
                          </p>
                        </TableCell>

                        <TableCell>
                          <IconButton
                            className="p-0"
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "fade-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                        <Menu
                          className="custom_popover"
                          id="fade-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          open={selectedIndex === i}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={() => handleView(item?.id)}>
                            View
                          </MenuItem>
                          <MenuItem onClick={() => handleUpdateStatus(item?.id, item?.status, i)}>
                            Update Status
                          </MenuItem>
                          
                        </Menu>
                      </TableRow>
                    );
                  })
                }
                { error &&   <NoRecordsFound tablecell={7} />}
                {data?.length === 0 && ( <NoRecordsFound tablecell={7} />)}
               
              </TableBody>
            </Table>

            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <div className="count_page">
                <span className="mr-10">Items per page :</span>
                <select
                  className="pagination_selectbox"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
              </div>
              <Stack spacing={2}>
                <Pagination  count={totalPage}  page={page} onChange={handlePaginationChange} />
              </Stack>
            </div>
          </Div>
        </CardContent>
      </Card>

      <div>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={filterOpen}
          onClose={handleFilterToggle}
        >
          <Box sx={{ width: "400px" }}>
            <div className="sidebar_header">
              <h1> Filter </h1>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={handleFilterClose}
              />
            </div>

            <div className="sideform_container">
              <form onSubmit={formik.handleSubmit}>
                <Div>
                  <Typography className="from_label">From Date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      name="fromDate"
                      className={
                        formik.touched.fromDate && formik.errors.fromDate
                          ? "form_new_style date_error"
                          : "form_new_style"
                      }
                      value={formik.values.fromDate}
                      open={dateopen}
                      slotProps={{
                        textField: { onClick: () => setDateOpen(true) },
                      }}
                      onClose={() => setDateOpen(false)}
                      onChange={(newDate, event) => {
                        setFromDate(newDate);
                        formik.setFieldValue("fromDate", newDate);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disableFuture
                      format="dd/MM/yyy"
                    />
                    {formik.touched.fromDate && formik.errors.fromDate ? (
                      <Typography color="error">
                        <FormHelperText>
                          {"From date is required"}
                        </FormHelperText>
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">To Date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={
                        formik.touched.toDate && formik.errors.toDate
                          ? "form_new_style date_error"
                          : "form_new_style"
                      }
                      name="toDate"
                      value={formik.values.toDate}
                      open={todateOpen}
                      slotProps={{
                        textField: { onClick: () => setTodateOpen(true) },
                      }}
                      onClose={() => setTodateOpen(false)}
                      onChange={(newDate, event) => {
                        setToDate(newDate);
                        formik.setFieldValue("toDate", newDate);
                      }}
                      disableFuture
                      format="dd/MM/yyy"
                      minDate={formik.values.fromDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {formik.touched.toDate && formik.errors.toDate ? (
                      <Typography color="error">
                        <FormHelperText>{formik.errors.toDate}</FormHelperText>
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">Status</Typography>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      style={{ width: "100%" }}
                      displayEmpty
                      MenuProps={{
                        classes: { paper: "" },
                      }}
                      renderValue={
                        formik.values.status !== ""
                          ? undefined
                          : () => "Select Status"
                      }
                    >
                      <MenuItem value={1}>New</MenuItem>
                      <MenuItem value={2}>Under Review</MenuItem>
                      <MenuItem value={3}>Interviewed</MenuItem>
                      <MenuItem value={4}>Offered</MenuItem>
                      <MenuItem value={5}>Rejected</MenuItem>
                    </Select>
                    {formik.touched.status && formik.errors.status ? (
                      <Typography color="error">
                        {formik.errors.status}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">Job Title</Typography>
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal 
                      disableClearable 
                      options={jobtitle}
                      getOptionLabel={(option) => option.jobTitle} 
                      value={jobtitle.find(
                        (job) => job.id === formik.values.job
                      )} 
                      onChange={handleJobChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Job Title"
                          error={
                            formik.touched.job && Boolean(formik.errors.job)
                          }
                          helperText={formik.touched.job && formik.errors.job}
                        />
                    
                      )}
                    />
                    {/* Add validation if needed */}
                  </FormControl>
                </Div>
                <Div
                  sx={{
                    mt: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LoadingButton
                    loading={loading}
                    className="filter_bttn"
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ marginRight: "10px" }}
                  >
                    Filter
                  </LoadingButton>

                  <Button
                    className="clear_btn"
                    onClick={handleClear}
                    variant="contained"
                    fullWidth
                    style={{ marginLeft: "10px" }}
                  >
                    Clear
                  </Button>
                </Div>
              </form>
            </div>
          </Box>
        </Drawer>
      </div>

      <div>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={updateStatusOpen}
          onClose={handleUpdateStatusClose}
        >
          <Box sx={{ width: "400px" }}>
            <div className="sidebar_header">
              <h1> Update Status </h1>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={handleUpdateStatusClose}
              />
            </div>

            <div className="sideform_container">
              <form onSubmit={Statusformik?.handleSubmit}>
                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">Status</Typography>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="status"
                      value={Statusformik?.values?.status}
                      onChange={Statusformik.handleChange}
                      style={{ width: "100%" }}
                      displayEmpty
                      MenuProps={{
                        classes: { paper: "" },
                      }}
                      renderValue={
                        Statusformik.values.status !== ""
                          ? undefined
                          : () => "Select Status"
                      }
                    >
                       {
                       StatusArray?.map(status => (
                         <MenuItem key={status?.value} value={status?.value}>
                           {status?.viewValue}
                         </MenuItem>
                       ))
                       }
                    
                   
                    </Select>
                  </FormControl>
                </Div>

                <Div
                  sx={{
                    mt: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LoadingButton
                    className="filter_bttn"
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ marginRight: "10px" }}
                  >
                    Submit
                  </LoadingButton>

                  <Button
                    className="clear_btn"
                    variant="contained"
                    fullWidth
                    style={{ marginLeft: "10px" }}
                    onClick={handleUpdateStatusClose}
                  >
                    Cancel
                  </Button>
                </Div>
              </form>
            </div>
          </Box>
        </Drawer>
      </div>
    </Fragment>
  );
};

export default AppliedJobList;
