import React, { useEffect, useState } from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";
import { Encrytion, NewdeCrypt } from "app/utils/EncryptionHelpers";
import { UpdateToken, logoutAction } from "app/redux/actions/authAction";
import { Navigate, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import AdminHeader from "app/pages/app-pages/TopNavbar/TopNavbar";
import moment from "moment";
import { ToastServices } from "app/services/toastServices";

const AdminLayout = ({ children }) => {
  const { setJumboLayoutOptions } = useJumboLayout();
  const navigate = useNavigate()
   React.useEffect(() => {
    setJumboLayoutOptions(layoutConfig);
    const accessToken = sessionStorage.getItem("token");
    if( !accessToken){
       navigate('/')
      logoutAction()
    }
}, []);
  const [expiryTime, setExpiryTime] = React.useState();
  const [tokenSet, setTokenSet] = useState(false);
    const verifyJWTFromSessionStorage = () => {
     const jwt = sessionStorage.getItem('token');
      return jwt ? true : false;
    };
  const [isToken, setIsToken] = useState(verifyJWTFromSessionStorage()); 
  const [isRefreshing, setIsRefreshing] = useState(false);
 
useEffect(() => {
  const accessToken = sessionStorage.getItem("token");
  if (!accessToken) {
    logoutAction();
    navigate('/');
    return;
  }else{
  setTokenSet(true);  
  findTokenTime();
}
  
  return () => clearTimeout();
}, []);

const findTokenTime = () => {

 const accessToken = sessionStorage.getItem("token");
 const decoded = jwtDecode(accessToken);

 if (isToken && !isRefreshing) {
    const timestamp = decoded.exp * 1000;
    const timeBeforeRefresh = timestamp - 10000;
    setExpiryTime(timestamp);

    const delay = timeBeforeRefresh - Date.now();
  //  console.log(moment(timestamp).format('dddd MMMM Do YYYY, h:mm:ss a'),"timestamp")
   // console.log("delay" , delay )
    setTimeout(refreshTokenFunction, delay);
  }
};

const refreshTokenFunction = async () => {
  try {
    setIsRefreshing(true);
    const token = sessionStorage.getItem("refreshToken");
    const Passdecrpt = Encrytion(token);
  
    const bodydata = { refresh_token: Passdecrpt };
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodydata),
    };
     // console.log(bodydata, options, 'api hit')
    const response = await fetch(`https://api.nimalaanenergies.com/admin/refresh-token`, options);
    if (!response.ok) throw new Error("Failed to set refresh token");

    const data = await response.json();
    if (data.status === false) throw new Error("Failed to set refresh token");

    const decrptyacess = NewdeCrypt(data?.accessToken);
    const decrptyRefesh = NewdeCrypt(data?.refresToken);
    UpdateToken(decrptyacess);
 // console.log(data?.accessToken,'new tokens access and refersh', data?.refresToken)
    sessionStorage.setItem("token", decrptyacess);
    sessionStorage.setItem("refreshToken", decrptyRefesh);
    sessionStorage.setItem("Version", new Date());

    setIsToken(true);
    findTokenTime();
  } catch (error) {
    ToastServices.ErroToast("Failed to set refresh token")
   // console.error("Failed to set refresh token", error);
    logoutAction();
    navigate('/');
  }finally {
    setIsRefreshing(false);
  }
};
  
return (
    <JumboLayout>
      <AdminHeader />
      
      <div style={{ padding: "23px 16px" }}>
        {children}
        </div>
      {/* <RefreshTokenService /> */}
    </JumboLayout>
  );
};
export default AdminLayout;