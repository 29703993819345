import React, { Fragment, useEffect, useState } from "react";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as yup from "yup";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import PasswordTooltip from "@jumbo/components/PasswordInfoTooltip/PasswordTooltip";
import { config } from "../../../config/main";
import { LoadingButton } from "@mui/lab";

const ChangePassword = () => {
  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "Password must be atleast 8 characters long")
  /*  .matches(/[A-Z]/, "Password must contain atleast one uppercase letter")  
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain atleast one special character"
      )  */
      .required("Old password is required"),

    passwordnew: yup
      .string()
      .min(8, "Password must be atleast 8 characters long")
      .matches(/[A-Z]/, "Password must contain atleast one uppercase letter")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain atleast one special character"
      )
      .test("not-same-as-old", "New password must not be same as old password", function(value) {
        const oldPassword = this.parent.password;
        return value !== oldPassword;
      })
      .required("New password is required"),

    passwordconfirm: yup
      .string()
      .oneOf([yup.ref("passwordnew")], "Passwords does not match")
      .required("Confirm password is required"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [conshowPassword, setConshowPassword] = useState(false);
  const [finshowPassword, setFinshowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const EncryptTOken = Encrytion(sessionStorage.getItem("token"));
    console.log(EncryptTOken, "EncryptTOkenEncryptTOken");
  }, []);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      password: "",
      passwordnew: "",
      passwordconfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("Form submitted:", values);

      const EncryptPassword = await Encrytion(values.password);
      const EncryptPasswordnew = await Encrytion(values.passwordnew);
      const EncryptPasswordconfirm = await Encrytion(values.passwordconfirm);
      const EncryptTOken = Encrytion(sessionStorage.getItem("token"));
      
      const userData = {
        password: EncryptPassword.toString(),
        passwordnew: EncryptPasswordnew.toString(),
        passwordconfirm: EncryptPasswordconfirm.toString(),
      };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${EncryptTOken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      };
      try {
        await fetch(`${config.apiUrl}/admin/change_password`, options)
          .then((res) => res.json())
          .then((res) => {
            console.log(res, "response");
            if (res?.status === true) {
              setLoading(false);
              ToastServices.SucessToast(res?.message);
              resetForm();
            } else if (res?.status === false) {
              ToastServices.ErroToast(res?.message);
              setLoading(false);
            } else if (res?.statusCode === 429) {
              setLoading(false);
              ToastServices.ErroToast("Something went wrong");
            } else if (res?.statusCode === 500) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            } else if (res?.statusCode === 404) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            }
          });
      } catch (err) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
        console.log(err, "Something went wrong");
      }
    },
  });

  return (
    <Fragment>
      <Typography variant={"h1"} mb={3} mt={1} fontWeight={600}>
        {"Change Password"}
      </Typography>

      <Card sx={{ maxWidth: 800, padding: "12px" }}>
        <CardContent>
          <div>
            <form
              className="h-100"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <div className="">
                <Div sx={{ mt: 0 }}>
                  <Typography className="login_label d-flex items-center g-10">
                    <span>
                      Old Password <span className="required">*</span>
                    </span> 
                    
                  </Typography>
                  <OutlinedInput
                    className="password_input "
                    name="password"
                    placeholder="Enter Old Password"
                    fullWidth={true}
                    error={touched.password && Boolean(errors.password)}
                    type={showPassword ? "text" : "password"}
                    required={true}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.password ? (
                    <small className="error_text">{errors.password}</small>
                  ) : (
                    ""
                  )}
                </Div>
                <Div sx={{ mt: 3 }}>
                  <Typography className="login_label d-flex items-center g-10">
                    <span>
                      New Password <span className="required">*</span>
                    </span>{" "}
                    &nbsp; <PasswordTooltip />
                  </Typography>
                  <OutlinedInput
                    className="password_input"
                    name="passwordnew"
                    placeholder="Enter New Password"
                    fullWidth={true}
                    error={touched.passwordnew && Boolean(errors.passwordnew)}
                    type={conshowPassword ? "text" : "password"}
                    required={true}
                    value={values.passwordnew}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setConshowPassword(
                              (conshowPassword) => !conshowPassword
                            )
                          }
                          edge="end"
                        >
                          {conshowPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.passwordnew ? (
                    <small className="error_text">{errors.passwordnew}</small>
                  ) : (
                    ""
                  )}
                </Div>
                <Div sx={{ mt: 3 }}>
                  <Typography className="login_label d-flex items-center g-10">
                    <span>
                      Confirm Password <span className="required">*</span>
                    </span>{" "}
                    &nbsp; <PasswordTooltip />
                  </Typography>
                  <OutlinedInput
                    className="password_input"
                    name="passwordconfirm"
                    placeholder="Enter Confirm Password"
                    fullWidth={true}
                    error={
                      touched.passwordconfirm && Boolean(errors.passwordconfirm)
                    }
                    type={finshowPassword ? "text" : "password"}
                    required={true}
                    value={values.passwordconfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setFinshowPassword(
                              (finshowPassword) => !finshowPassword
                            )
                          }
                          edge="end"
                        >
                          {finshowPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.passwordconfirm ? (
                    <small className="error_text">
                      {errors.passwordconfirm}
                    </small>
                  ) : (
                    ""
                  )}
                </Div>
              </div>
              <div className="mt-20">
                <LoadingButton
                  loading={loading}
                  className="primary_btn w-365"
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                >
                  Change Password
                </LoadingButton>
              </div>
            </form>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default ChangePassword;
