import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { FaCircleInfo } from "react-icons/fa6";


const PasswordTooltip = () => {
  return (
    <div>
      <Tooltip
        className="cursor-pointer"
        title={
          <Typography className="infotext">
            Password must contain atleast <br /> &bull; 8 Characters <br />{" "}
            &bull; 1 Uppercase letters
            <br /> &bull; 1 Special characters
          </Typography>
        }
      >
        {" "}
        <FaCircleInfo />
      </Tooltip>
    </div>
  );
};

export default PasswordTooltip;
