import React from 'react'
import { NO_Recods } from 'app/assets'

const NodataFound = () => {
  return (
    <div className='no-records'>
        
        <img height={300} width={300} src={NO_Recods} alt="no-recods-found" />
        <h3>No data found</h3>

    </div>
  )
}

export default NodataFound