import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockResetIcon from '@mui/icons-material/LockReset';
import { store } from '../redux/store';
import { responsiveFontSizes } from '@mui/material';
import { NewdeCrypt } from './EncryptionHelpers';

const getRollPermissions = async () => {
    const id = sessionStorage.getItem('rollId');
    const state = store.getState();
    const decrprt = sessionStorage.getItem('token')
    const token = state?.auth?.accessToken === null ? decrprt : state?.auth?.accessToken;
    let data;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    await fetch(`https://nimalaan-api.technogenesis.in/permission/detail/${id}`, options)
    .then(res => res.json())
    .then((res) => {
        if(res.status === true) {
            data = res.data;
        } else {
            data = false;
        }
    })
    return data;
}

export const getModulePermission = async () => {
    const menu = [
        {   
            id: 1,
            uri: "settings/manage-roles",
            label: 'Manage Roles',
            type: "nav-item",
            icon: <ListAltIcon sx={{fontSize: 20}}/>
        },
        {   
            id: 2,
            uri: "settings/manage-users",
            label: 'Manage Users',
            type: "nav-item",
            icon: <AccountCircleIcon sx={{fontSize: 20}}/>
        },
        {   
            id: 13,
            uri: "/change-password",
            label: 'Change Password',
            type: "nav-item",
            icon: <LockResetIcon sx={{fontSize: 20}}/>
        }
    ]
    let resData;
    await getRollPermissions().then(res => {
       resData = res[0]
    })
    const permissions = resData?.role_permissions;

    const menuIds = permissions?.map(i => {
        return i.acl_menu_module_id
    });
    let menuModules = menu?.filter(item => menuIds?.includes(item.id));
    return menuModules;
}


export const hasActionPermissionList = async (url) => {
    console.log(url, "urlurlurlurlurlurl")
    let actionList, menuList, actionMenu = [];
    await getRollPermissions().then(res => {
        actionList = res[0]
    });

    await getModulePermission().then(res => {
        menuList = res;
    })

    const permittedMenuIds = menuList?.map(i => i.id);
    
    const actionPermission = actionList?.role_permissions;

    const actionMenuIds = actionPermission?.map(i => i.acl_menu_id)

    actionList?.tabular_view?.filter(i=>permittedMenuIds.includes(i.id))
    .map((item)=> {
        item.action?.filter(ele => actionMenuIds?.includes(ele.acl_menu_id))
        .map(ele => {
            actionMenu.push(ele)
        })
    })

    return actionMenu?.some(ele => ele.url === url)
} 



