import React, {  useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  FormHelperText,
  
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { config } from "app/config/main";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import { RxCross2 } from "react-icons/rx";
import { IoAdd } from "react-icons/io5";


const UploadGallary = ({ open, id, dialogOpened, getViewGallery }) => {
  const EncryptTOken = Encrytion(sessionStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const thumnailRef = useRef(null);
  const videothumnailRef = useRef(null);
  const videoRef = useRef(null);
  const [thumb, setThumb] = useState(null);
  const [videothumb, setVideoThumb] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const formikRef = useRef();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imgloader, setImageLoader] = useState(0);
  const [vidimgloader, setVidImageLoader] = useState(0);

  // Change radio 
  const handleRadioChange = (e, setFieldValue, setFieldError) => {
    setFieldValue("type", e.target.value);

    if (e.target.value == 1) {
      setVideoFile(null);
      setVideoThumb(null);
      setFieldValue("videothumbnail", "");
      setFieldValue("galleryVideos", "");
      setFieldError("videothumbnail", "");
      setFieldError("galleryVideos", "");
    }
    if (e.target.value == 2) {
      setThumb(null);
      setFieldError("images", "");
      setFieldValue("images", "");
    }
  };

 
//  validation 
  const validationSchema = yup.object({
    type: yup.string().required("Please select image or video"),
    images: yup.mixed().when("type", {
      is: "1",
      then: yup.mixed().required("Please upload an image file"),
      otherwise: yup.mixed(),
    }),
    videothumbnail: yup.mixed().when("type", {
      is: "2",
      then: yup.mixed().required("Please upload a cover image file"),
      otherwise: yup.mixed(),
    }),
    galleryVideos: yup.mixed().when("type", {
      is: "2",
      then: yup.mixed().required("Please upload a video file"),
      otherwise: yup.mixed(),
    }),
  });

  const initialValues = {
    type: "1",
    images: "",
    videothumbnail: "",
    galleryVideos: "",
  };
   
  // for image 
  const handleFileChange = async (event, setFieldValue) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      const validFileExtensions = ["jpeg", "jpg", "png"];
      const maxFileSize = 50 * 1024 * 1024;

      if (validFileTypes.includes(fileType) && validFileExtensions.includes(fileExtension)) {
        if (file.size <= maxFileSize) {
          const img = new Image();
          img.onload = async () => {
            const aspectRatio = img.width / img.height;
            const isValidAspectRatio = Math.abs(aspectRatio - 1 / 1) < 0.01;

            if (isValidAspectRatio) {

                const formData = new FormData();
                formData.append("file", file);
      
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${config.apiUrl}/upload`, true);
      
                
                xhr.upload.onprogress = (event) => {
                  if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    setImageLoader(percent);
                   
                  }
                };
      
                xhr.onload = async () => {
                  if (xhr.status === 201) {
                    const data = JSON.parse(xhr.responseText);
                   
                    setThumb(data?.url);
                    setFieldValue("images", data?.image);
                    setImageLoader(0); 
                  } else {
                    const errorData = JSON.parse(xhr.responseText);
                    ToastServices.ErroToast(`Upload failed: ${errorData.message}`);
                  }
                };
      
                xhr.onerror = () => {
                  ToastServices.ErroToast(`Error uploading file: ${xhr.statusText}`);
                };
      
                xhr.send(formData);
              
            } else {
              ToastServices.ErroToast("Invalid aspect ratio.");
            }
          };
          img.onerror = () => {
            ToastServices.ErroToast("Error loading image.");
          };
          img.src = URL.createObjectURL(file);
        } else {
          ToastServices.ErroToast("File is too large.");
        }
      } else {
        ToastServices.ErroToast("Invalid file type.");
      }

      event.target.value = null;
    }
  };

  const handleRemoveFile = (setFieldValue) => {
    setThumb(null);
    setFieldValue("images", "");

    if (thumnailRef.current) {
      thumnailRef.current.value = null;
    }
  };

  // for videothumbnail

  const handleVideoImgChange = async (event, setFieldValue) => {
    const file = event.target.files[0];

   

    if (file) {
      const fileType = file.type;
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxFileSize = 50 * 1024 * 1024;
      const validFileExtensions = ["jpeg", "jpg", "png"];
      if (validFileTypes.includes(fileType) && validFileExtensions.includes(fileExtension)) {
        if (file.size <= maxFileSize) {
          const img = new Image();
          img.onload = async () => {
            const aspectRatio = img.width / img.height;
            const isValidAspectRatio = Math.abs(aspectRatio - 1 / 1) < 0.01;

            if (isValidAspectRatio) {

                const formData = new FormData();
                formData.append("file", file);
      
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${config.apiUrl}/upload`, true);
      
                
                xhr.upload.onprogress = (event) => {
                  if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    setVidImageLoader(percent);
                   
                  }
                };
      
                xhr.onload = async () => {
                  if (xhr.status === 201) {
                    const data = JSON.parse(xhr.responseText);
                    
                    setVideoThumb(data?.url);
                    setFieldValue("videothumbnail", data?.image);
                    setVidImageLoader(0); 
                  } else {
                    const errorData = JSON.parse(xhr.responseText);
                    ToastServices.ErroToast(`Upload failed: ${errorData.message}`);
                  }
                };
      
                xhr.onerror = () => {
                  ToastServices.ErroToast(`Error uploading file: ${xhr.statusText}`);
                };
      
                xhr.send(formData);
              
            } else {
              ToastServices.ErroToast("Invalid aspect ratio.");
            }
          };
          img.onerror = () => {
            ToastServices.ErroToast("Error loading image.");
          };
          img.src = URL.createObjectURL(file);
        } else {
          ToastServices.ErroToast("File is too large.");
        }
      } else {
        ToastServices.ErroToast("Invalid file type.");
      }

      event.target.value = null;
    }


  };
  const handleRemoveVideoImg = (setFieldValue) => {
    setVideoThumb(null);
    setFieldValue("videothumbnail", "");

    if (videothumnailRef.current) {
      videothumnailRef.current.value = null;
    }
  };

  // for video

  const handleVideoFileChange = async (event, setFieldValue) => {
    const file = event.target.files[0];

    if (file) {
      const validFileTypes = ["video/mp4", "video/mpeg", "video/quicktime"];
      const maxFileSize = 100 * 1024 * 1024;

      if (validFileTypes.includes(file.type)) {
        if (file.size <= maxFileSize) {
          const formData = new FormData();
          formData.append("file", file);

          const xhr = new XMLHttpRequest();
          xhr.open("POST", `${config.apiUrl}/upload`, true);

          // Setup progress event listener
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percent = Math.round((event.loaded / event.total) * 100);
              setUploadProgress(percent);
             
            }
          };

          xhr.onload = async () => {
            if (xhr.status === 201) {
              const data = JSON.parse(xhr.responseText);
            
              setVideoFile(data?.url);
              setFieldValue("galleryVideos", data?.image);
              setUploadProgress(0); // Reset progress on successful upload
            } else {
              const errorData = JSON.parse(xhr.responseText);
              ToastServices.ErroToast(`Upload failed: ${errorData.message}`);
            }
          };

          xhr.onerror = () => {
            ToastServices.ErroToast(`Error uploading file: ${xhr.statusText}`);
          };

          xhr.send(formData);
        } else {
          ToastServices.ErroToast("File is too large.");
        }
      } else {
        ToastServices.ErroToast("Invalid file type.");
      }
    }
    event.target.value = null; // Reset file input
  };

  const handleRemoveVideo = (setFieldValue) => {
    setVideoFile(null);
    setFieldValue("galleryVideos", "");

    if (videoRef.current) {
      videoRef.current.value = null;
    }
  };

  const postGall = async (values) => {
    const userData = {
      eventId: `${id}`,
      type: values?.type,
      images: values?.type == 1 ? values?.images : values?.galleryVideos,
      videoThumbnailImage: values?.videothumbnail,
    };
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };
    try {
      const postResponse = await axios.post(
        `${config.apiUrl}/events/gallery/create`,
        userData,
        {
          headers,
        }
      );

   

      if (postResponse?.data?.status === true) {
        ToastServices.SucessToast(postResponse?.data?.message);
      } else if (postResponse?.data?.status === false) {
        ToastServices.ErroToast(postResponse?.data?.message);
      } else if (postResponse?.data?.statusCode === 429) {
        ToastServices.ErroToast("Something went wrong");
      } else if (postResponse?.data?.statusCode === 500) {
        ToastServices.ErroToast("Something went wrong");
      } else if (postResponse?.data?.statusCode === 404) {
        ToastServices.ErroToast("Something went wrong");
      }
    } catch (e) {
    }
  };

  const ClosePopup = (resetForm, setFieldValue) => {
    dialogOpened(false);
    resetForm();
    setVideoFile(null);
    setVideoThumb(null);
    setFieldValue("videothumbnail", "");
    setFieldValue("galleryVideos", "");
    setThumb(null);
    setFieldValue("images", "");
    getViewGallery();
  };

  const ClosePopupSave = async (isValid, setFieldValue) => {
    const formik = formikRef.current;
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      await dialogOpened(false);
      await getViewGallery();
      formikRef.current.resetForm();
    } else {
    }
  };

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={async (values, event, actions) => {
          await postGall(values);
          await getViewGallery();
          formikRef.current.resetForm();
          setVideoFile(null);
          setVideoThumb(null);
          setThumb(null);
        }}
      >
        {({values,setFieldValue,errors,touched,setFieldError,resetForm,isValid,}) => (
          <Dialog
            open={open}
            onClose={() => ClosePopup(resetForm, setFieldValue)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Form>
              <DialogTitle id="alert-dialog-title">
                {"Upload Gallary"}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => ClosePopup(resetForm, setFieldValue)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <div>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="type"
                      value={values.type}
                      onChange={(e) =>
                        handleRadioChange(e, setFieldValue, setFieldError)
                      }
                      className="radio-grp"
                    >
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Type
                      </FormLabel>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Image"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Video"
                      />
                    </RadioGroup>
                  </FormControl>

                  {values.type === "1" && (
                    <Grid item xs={12} md={12}>
                      <Typography className="login_label d-flex items-center g-10">
                        Upload Image <span className="required">*</span>
                      </Typography>
                      <div className="uploader_row">
                        <input
                          type="file"
                          ref={thumnailRef}
                          style={{ display: "none" }}
                          accept="image/jpeg, image/png, image/jpg"
                          onChange={(event) =>
                            handleFileChange(event, setFieldValue)
                          }
                        />
                        {!thumb && (
                          <div
                            className="image_uploader"
                            onClick={() => {
                              thumnailRef.current.click();
                            }}
                          >
                            <IoAdd fontSize={24} />
                            
                             {
                                imgloader > 0 && (
                                  <div className="upload_loader" onClick={(event)=> event.stopPropagation()}>
                                  <span>{imgloader}%</span>
                               </div>
                                )
                              }
                          </div>
                        )}
                        {thumb && (
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <Box
                              sx={{
                                position: "relative",
                                width: 100,
                                height: 100,
                                border: "1px solid grey",
                                borderRadius: 1,
                                marginRight: "15px",
                              }}
                            >
                              <img
                                src={thumb}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  marginRight: "15px",
                                }}
                              />
                              <span
                                className="close_icon"
                                onClick={() => {
                                  handleRemoveFile(setFieldValue);
                                }}
                              >
                                <RxCross2 />
                              </span>
                            </Box>
                          </Box>
                        )}
                      </div>

                      <div className="upload_notes">
                        <p>
                          <span>Notes:</span> Image format should be
                          jpg,png,jpeg and size should not exceed 50mb
                        </p>
                        <p>
                          {" "}
                          <span>Aspect Ratio</span> : 1:1
                        </p>
                      </div>
                      {touched.images && errors.images ? (
                        <FormHelperText>{errors.images}</FormHelperText>
                      ) : null}
                    </Grid>
                  )}

                  {values.type === "2" && (
                    <Grid container spacing={3}>
                      <Grid item xs={6} md={6}>
                        <Typography className="login_label d-flex items-center g-10">
                          Upload Cover Image <span className="required">*</span>
                        </Typography>
                        <div className="uploader_row">
                          <input type="file"ref={videothumnailRef}style={{ display: "none" }}accept="image/jpeg, image/png, image/jpg"
                            onChange={(event) =>
                              handleVideoImgChange(event, setFieldValue)
                            }
                          />
                          {!videothumb && (
                            <div
                              className="image_uploader"
                              onClick={() => {
                                videothumnailRef.current.click();
                              }}
                            >
                              <IoAdd fontSize={24} />
                              {
                                vidimgloader > 0 && (
                                  <div className="upload_loader" onClick={(event)=> event.stopPropagation()}>
                                  <span>{vidimgloader}%</span>
                               </div>
                                )
                              }
                            </div>
                          )}
                          {videothumb && (
                            <Box sx={{display: "flex",gap: 2,}}>
                              <Box sx={{position: "relative",width: 100,height: 100,border: "1px solid grey",borderRadius: 1,marginRight: "15px",}}>
                                <img src={videothumb}alt="Preview"style={{width: "100%",height: "100%",objectFit: "cover",marginRight: "15px",}}
                                />
                                <span className="close_icon"onClick={() => {handleRemoveVideoImg(setFieldValue);}}>
                                  <RxCross2 />
                                </span>
                              </Box>
                            </Box>
                          )}
                        </div>
                        <div className="upload_notes">
                          <p>
                            <span>Notes:</span> Image format should be
                            jpg,png,jpeg and size should not exceed 50mb
                          </p>
                          <p>
                            {" "}
                            <span>Aspect Ratio</span> : 1:1
                          </p>
                        </div>
                        {touched.videothumbnail && errors.videothumbnail ? (
                          <FormHelperText>
                            {errors.videothumbnail}
                          </FormHelperText>
                        ) : null}
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <Typography className="login_label d-flex items-center g-10">
                          Upload Video <span className="required">*</span>
                        </Typography>
                        <div className="uploader_row">
                          <input
                            type="file"
                            ref={videoRef}
                            style={{ display: "none" }}
                            accept="video/mp4"
                            onChange={(event) =>
                              handleVideoFileChange(event, setFieldValue)
                            }
                          />
                          {!videoFile && (
                            <div
                              className="image_uploader"
                              onClick={() => {
                                videoRef.current.click();
                              }}
                            >
                              <IoAdd fontSize={24} />
                              {
                                uploadProgress > 0 && (
                                  <div className="upload_loader" onClick={(event)=> event.stopPropagation()}>
                                  <span>{uploadProgress}%</span>
                               </div>
                                )
                              }
                            </div>
                          )}
                          {videoFile && (
                            <Box sx={{display: "flex",gap: 2,}}>
                              <Box sx={{position: "relative",width: 100,height: 100,border: "1px solid grey",borderRadius: 1,marginRight: "15px"}}
                              >
                                <video
                                  src={videoFile}
                                  width="100"
                                  height="100"
                                  controls
                                ></video>

                                <span
                                  className="close_icon"
                                  onClick={() =>
                                    handleRemoveVideo(setFieldValue)
                                  }
                                >
                                  <RxCross2 />
                                </span>
                              </Box>
                            </Box>
                          )}
                        </div>

                        <div className="upload_notes">
                          <p>
                            {" "}
                            Video format should be mp4 and size should not
                            exceed 50mb
                          </p>
                        </div>
                        {touched.galleryVideos && errors.galleryVideos ? (
                          <FormHelperText>
                            {errors.galleryVideos}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                    </Grid>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <div className="submit-btn-section">
                  <LoadingButton
                    className="filter_bttn p-0"
                    loading={loading}
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{
                      marginRight: "10px",
                      width: 100,
                      fontSize: "20px",
                    }}
                  >
                    Save
                  </LoadingButton>
                  <LoadingButton
                    className="save_btn"
                    loading={loading}
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={() => ClosePopupSave(isValid)}
                    style={{ width: 180 }}
                  >
                    Save & Close
                  </LoadingButton>
                </div>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(UploadGallary);
