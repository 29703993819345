import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "@jumbo/components/JumboAuthProvider/JumboAuthContext";
import useRoutePathMatch from "@jumbo/hooks/useRoutePathMatch";
import {
  routesForAuthenticatedOnly,
  routesForNotAuthenticatedOnly,
  routesForCurrent,
  routesForAdmin,
  routesAdminwithauth,
} from "../../../app/routes";
import { removeToken, storeToken } from "./authHelpers";
import { config } from "../../../app/config/main";

const storedToken = sessionStorage.getItem("token");
let firstTimePageLoad = true;

const init = () => {
  let authUser = null;

  if (!config?.authSetting) {
    throw Error(
      `You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
    );
  }

  if (storedToken) {
    storeToken(storedToken); // also sets axios header
  }

  return {
    authToken: storedToken ?? null,
    authUser: authUser,
    isLoading: false,
    fallbackPath: config.authSetting.fallbackPath,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "set-auth-data":
      return {
        ...state,
        ...action.payload,
      };

    case "start-loading":
      return {
        ...state,
        isLoading: true,
      };

    case "stop-loading":
      return {
        ...state,
        isLoading: false,
      };
  }
};

const JumboAuthProvider = ({ children, ...restProps }) => {
  const [tokened, setToken] = useState(() => sessionStorage.getItem("token"));
  const [authOptions, setAuthOptions] = React.useReducer(
    authReducer,
    restProps,
    init
  );
  const [logout, setLogout] = React.useState(false);
  const navigate = useNavigate();
  const notAuthenticated = [
    "/current-user/login",
    "/signup",
    "/current-user/forgot-password",
    "/current-user/reset-password",
    "/current-user/set-password",
    "/current-user/success-message",
    "/login",
    "/forgot-password",
    `/reset-password/:token`,
    "/success-message",
  ];
  const adminNotAuth = [
    "/login",
    "/forgot-password",
    `/forgot-password/:id`,
    "/success-message",
  ];
  const isAuthenticatedRouteOnly = useRoutePathMatch(
    routesForAuthenticatedOnly
  );
  const isNotAuthenticatedRouteOnly = useRoutePathMatch(
    routesForNotAuthenticatedOnly
  );

  useEffect(() => {
    sessionStorage.setItem("tokened", tokened);
  }, [tokened]);

  useEffect(() => {
    if (logout) {
      removeToken();
      setAuthOptions({
        type: "set-auth-data",
        payload: { authToken: null, authUser: null, isLoading: false },
      });
      setLogout(false);
    }
  }, [logout]);

  const setAuthToken = React.useCallback(async (token) => {
    setAuthOptions({ type: "start-loading" });
    if (!token) {
      setLogout(true);
      return;
    }

    storeToken(token);
    try {
      const authUser = await config?.authSetting?.getAuthUserService();
      if (authUser) {
        setAuthOptions({
          type: "set-auth-data",
          payload: { authToken: token, authUser: authUser, isLoading: false },
        });
        return;
      }
      setLogout(true);
    } catch (error) {
      setLogout(true);
      console.error(error);
    }
  }, []);

  //todo: maybe in next version
  const setRedirectPath = React.useCallback((redirectPath) => {
    setAuthOptions({ type: "set-redirect-path", payload: { redirectPath } });
  }, []);

  const setAuthData = React.useCallback((data) => {
    setAuthOptions({ type: "set-auth-data", payload: data });
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
      tokened,
      setToken,
    };
  }, [authOptions]);

  useEffect(() => {
    const requestPath = window.location.pathname;
    const portal = sessionStorage.getItem("portal");
    const current = routesForCurrent.map((route) => route.path);

    if (portal === "admin" && current.includes(requestPath)) {
      navigate("/manage-enquiry", { replace: true });
    }

    if (!authOptions.authToken) {
    } else if (!authOptions.authUser) {
      setAuthToken(authOptions.authToken);
    } else if (isNotAuthenticatedRouteOnly) {
      if (!firstTimePageLoad)
        navigate(config.authSetting.redirectNotAuthenticatedPath ?? "/");
      else firstTimePageLoad = false;
    }

    if (
      authOptions.authToken &&
      notAuthenticated.includes(requestPath) === true
    ) {
      const portal = sessionStorage.getItem("portal");
      if (portal === "admin") {
        navigate("/settings/manage-enquiry", { replace: true });
      } else if (portal === "user") {
        navigate("/curent-user/dashboard", { replace: true });
      }
    }
  }, [authOptions.authUser]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default JumboAuthProvider;
