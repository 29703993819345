import React from 'react';
import Avatar from "@mui/material/Avatar";
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { logoutAction } from 'app/redux/actions/authAction';
import { useSelector } from 'react-redux';
import {ASSET_AVATARS} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import useApp from 'app/hooks/useApp';

const AuthUserDropdown = ({visibility}) => {
     visibility = true
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const {setAuthToken} = useJumboAuth();
    const userData = useSelector(state => state?.user?.userData);
    const user = useApp();
   // console.log(user, '1321564654')
    // const userData = user.userData
    const profile_pic = getAssetPath(`${ASSET_AVATARS}/avatar.png`, `60x60`);
   // console.log(visibility, 'visibility in authUser')
    const onLogout = () => {
        logoutAction();
        visibility = false
        // window.location.reload(false);
        navigate('/')
         
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    let isOpen = Boolean(anchorEl);

    const handleProfile = () => {
        visibility = false
        navigate('/change-password')
        setAnchorEl(null);
    }

    const popuphandleClick = React.useCallback((event) => {
        //isOpen = Boolean(anchorEl);
    
        setAnchorEl(event.currentTarget);
      }, []);
    
      const popuphandleClose = React.useCallback(() => {
        setAnchorEl(null);
      });

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
            handleClick={popuphandleClick}
            handleClose={popuphandleClose}
            
            anchorEl={anchorEl}
            isOpen={isOpen}
                triggerButton={
                    <Avatar
                        src={profile_pic}
                        sizes={"small"}
                        sx={{boxShadow: 25, cursor: 'pointer'}}
                    />
                }
            >
              {/**   <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={profile_pic} alt={userData?.name} sx={{width: 60, height: 60, mb: 2}}/>
                    <Typography variant={"body1"} color="text.secondary">{userData?.email}</Typography>
                </Div>
                <Divider/> */}
                <nav>
                    <List disablePadding sx={{pt:1,pb: 1}}>
                        
                        <ListItemButton  onClick={handleProfile}>
                            <ListItemIcon sx={{minWidth: 30}}>
                                <HttpsOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Change Password" sx={{my: 0}}/>
                        </ListItemButton>
                        
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 30}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{my: 0}}/>
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
