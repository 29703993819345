import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Drawer,
  TextField,
  Typography,
  FormControl,
  Select, 
  MenuItem
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers';
import { config } from "app/config/main";
import { useFormik } from "formik";
import * as yup from "yup";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";

const UpdateStatus = ({
  id,
  handleDrawerToggle,
  mobileOpen,
  handleDrawerClose,
  loadData,
 }) => {
 
  const [fromDate, setFromDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const getToken = sessionStorage.getItem("token")
  const decrpttoken = Encrytion(getToken)
  const [selectedDate, setSelectedDate] = useState(null);
  const [age, setAge] = React.useState("");
  const [getstatus, setGetstatus] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dateopen, setDateOpen] = React.useState(false);
  
const validationSchema = yup.object().shape({
  status: yup.string().nullable("Status is required").required("Status is required"),
  date: yup
    .date('Date is Required')
    .nullable('Date is Required')
    .typeError('Date is required')
    .when("status", {
      is: (val) => val === "2",
      then: yup.date().required("Date is required").typeError('Date is required'),
    }),
  notes: yup.string().required("Notes is required"),
});


const formik = useFormik({
  initialValues: {
    status: "",
    date: null,
    notes: "",
  },
  validationSchema: validationSchema,
  onSubmit: (values) => {
    
    const data = {
      enquiryId:id,
      followUpDate: values?.status === 2 ? formatDate(values.date) : ""  ,
      statusId: values?.status,
      notes: values?.notes,
    };
    //console.log(data, "data");
    handleSubmitForm(data);
  
  }
})

const handleSubmitForm = async (data) =>{
  const options = {
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${decrpttoken}`
    },
    body: JSON.stringify(data)
}
await fetch(`${config.apiUrl}/enquiry/status-update`, options).then(res => res.json())
.then(res =>{
  //console.log(res, "response")
  if(res?.status === true){
    ToastServices.SucessToast(res?.message)
    setLoading(false)
    loadData()
    handleDrawerClose()
    formik.resetForm() 
    setSelectedDate('')
    setSelectedStatus('')
  }else if(res?.status === false){
    ToastServices.ErroToast(res?.message)
    setLoading(false)

  } else if (res?.statusCode === 429){
    setLoading(false)
    ToastServices.ErroToast(res?.message)
   } else if (res?.statusCode === 500){
    ToastServices.ErroToast(res?.message)
   }
})
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const handleChange = (event) => {
  const { name, value } = event.target;
  setSelectedStatus(value);
  formik.handleChange(event);

};

useEffect(() => {
  const getStatusApi = async () => {
    const options = {
      method: "GET",
    };

    await fetch(`${config.apiUrl}/status`, options)
      .then((res) => res.json())
      .then((res) => setGetstatus(res?.data));
  };

  const fetchDataWithDelay = async () => {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    await getStatusApi();
  };
  getStatusApi();
}, []);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    // If Follow Up is selected, clear the date
    if (newValue && newValue.statusName === "Follow Up") {
      setFromDate(null);
    }
  };
 
 
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={()=>{
          handleDrawerClose()
          formik.resetForm()
          setSelectedDate('')
          setSelectedStatus('')
        }}
      >
        <Box sx={{ width: "400px" }}>
          <div className="sidebar_header">
            <h1> Update Status </h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={()=>{
              handleDrawerClose()
              formik.resetForm()
              setSelectedDate('')
              setSelectedStatus('')
            }} />
          </div>

          
            <div className="sideform_container">
            <form onSubmit={formik.handleSubmit}>
                
                <Div >
                  <Typography className="from_label">
                    Status <span className="text-danger">*</span>
                  </Typography>

                  <FormControl fullWidth> 
                  
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  placeholder="Select Status"
                  value={formik.values.status}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  displayEmpty
                 // renderValue={formik.values.status !== "" ? undefined : () => {<span className="placeholder_txt">Select status</span>}}
                 renderValue={formik.values.status !== "" ? undefined : () => "Select Status"}
                  MenuProps={{
                    classes: { paper: "custom-menu-paper-select" }, // Assign a class to the dropdown menu
                  }}
                >
                    {/**   <MenuItem selected disabled value="" >Select Status</MenuItem> */}
                  {getstatus?.filter(item => item.id !== 1).map((item) => {
                    return (
                      <MenuItem value={item?.id}>{item?.statusName}</MenuItem>
                    );
                  })}
                </Select>
                 
                {formik.touched.status && formik.errors.status ? (
                  <Typography color="error">
                    {formik.errors.status}
                  </Typography>
                ) : null}
              </FormControl>
 
                </Div>
                 
                {selectedStatus === 2 && (
                <Div sx={{ mt: 3 }}>
                 
                <Typography className="from_label">
                    Date <span className="text-danger">*</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="date"
                    className="form_new_style"
                    value={selectedDate}
                    onChange={(newDate, event) => {
                     // console.log("newDate", newDate);
                      // setSelectedDate(newDate);
                      formik.setFieldValue("date", newDate);
                    }}
                    open={dateopen}
                    slotProps={{  textField: { onClick: () => setDateOpen(true),  }}}
                    onClose={() => setDateOpen(false)}
                   
                    renderInput={(params) => (
                      <TextField 
                      {...params} 
                      name="date" 
                      
                      />
                    )}
                     disablePast
                    format="dd/MM/yyy"
                  />
                   {formik.touched.date && formik.errors.date ? (
                    <Typography color="error">
                      {formik.errors.date}
                    </Typography>
                  ) : null}
          </LocalizationProvider>
                           
                              
                            </Div>
                )}

   

                <Div sx={{ mt: 3 }}>
                  <Div className="dlfex-justify-between-align-center">
                     
                    <Typography className="from_label">
                      Notes <span className="text-danger">*</span>
                    </Typography> 
                    
                  </Div>
                  <TextField
                name="notes"
                className="form_new_style"
                placeholder="Enter your Notes Here..."
                multiline
                rows={5}
                maxRows={4}
                fullWidth
                value={formik.values.notes}
                onChange={formik.handleChange}
              />
       {formik.touched.notes && formik.errors.notes ? (
                <Typography color="error">{formik.errors.notes}</Typography>
              ) : null}
      
                </Div>
                
                <Div sx={{ mt: 3 }}>
                <LoadingButton 
                loading={loading} 
                    className="login_btn"
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                  >
                    Update Status
                  </LoadingButton>
                </Div>
              </form>
            </div>
          
            
           
         
        </Box>
      </Drawer>
    </div>
  );
};

export default UpdateStatus;
