import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import * as yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import { config } from "../../../config/main";
import { useContext } from "react";
import { Encrytion, NewdeCrypt } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import { AuthContext } from "@jumbo/components/JumboAuthProvider";
import { AppLogo } from "app/assets";

const validationSchema = yup.object({
  email: yup
    .string("")
    .email("Enter a valid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .required("email address is required"),
});

const ForgotPass = () => {
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("Form submitted:", values);
      const EncryptEmail = await Encrytion(values.email);
      const userData = {
        email: EncryptEmail.toString(),
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      };
      try {
        await fetch(`${config.apiUrl}/admin/forgot-password`, options)
          .then((res) => res.json())
          .then((res) => {
            console.log(res, "response");
            if (res?.status === true && res?.link) {
              ToastServices.SucessToast(res?.message);
              const Link = res?.link;
              const token = Link.split("/").slice(3).join("/");
              console.log(token, "**");
              setLoading(false);
              navigate(`/`, { replace: true });
            }
            if (res?.status === false) {
              ToastServices.ErroToast(res?.message);
              setLoading(false);
            } else if (res?.statusCode === 429) {
              setLoading(false);
              ToastServices.ErroToast("Something went wrong");
            } else if (res?.statusCode === 500) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            } else if (res?.statusCode === 404) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            }
          });
      } catch (err) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
        console.log(err, "Something went wrong");
      }
    },
  });

  return (
    <Div className="current_user_session ">
      <div className=" flex-center-col ">
        <div className="current_user_session_card">
          <div style={{ textAlign: "center" }}>
            <img height={60} src={AppLogo} alt="nimalaan-admin-logo" />
          </div>
          <form
            className="h-100"
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <div className="current_session_form justify-between h-100">
              <div className="w-100 ">
                <Typography
                  sx={{ textAlign: "center", mt: 1 }}
                  className="login_greeting"
                >
                  Forgot Password?
                </Typography>
                <Typography textAlign={"center"}>
                  No worries, we’ll send you reset instructions.
                </Typography>
                <Div sx={{ mt: 4 }}>
                  <Typography className="login_label">
                    Email Address <span className="required">*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    name="email"
                    placeholder="Enter Email Address"
                    type="email"
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Div>
              </div>
              <Div className="w-100 mt-50">
                <LoadingButton
                  loading={loading}
                  className="login_btn"
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                >
                  Reset Password
                </LoadingButton>
                <Typography textAlign={"center"}>
                  <Link
                    className="back_to_login"
                    underline="none"
                    href="/"
                  >
                     Back to Login
                  </Link>
                </Typography>
              </Div>
            </div>
          </form>
        </div>
      </div>
    </Div>
  );
};

export default ForgotPass;
