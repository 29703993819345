import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import { config } from "../../../config/main";


const CreateJobSkill = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const regexString = "^\\S$|^\\S[\\s\\S]*\\S$";
  const validationSchema = yup.object({
    jobskill: yup.string().matches(new RegExp(regexString), "Please provide valid job skill").required("Job skill is required"),
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      jobskill: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const EncryptToken = Encrytion(sessionStorage.getItem("token"));
      const userData = {
        name: values?.jobskill,
      };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${EncryptToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      };

      try {
        await fetch(`${config.apiUrl}/job-skills/create`, options)
          .then((res) => res.json())
          .then((res) => {
            if (res?.status === true) {
              setLoading(false);
              ToastServices.SucessToast(res?.message);
              navigate("/settings/manage-job-skill");
            } else if (res?.status === false) {
              ToastServices.ErroToast(res?.message);
              setLoading(false);
            } else if (res?.statusCode === 429) {
              setLoading(false);
              ToastServices.ErroToast("Something went wrong");
            } else if (res?.statusCode === 500) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            } else if (res?.statusCode === 404) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            }
          });
      } catch (err) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
      }
    },
  });

  const handleBacktoList = () => {
    navigate("/settings/manage-job-skill");
  };

  return (
    <Fragment>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >{`Create Job Skill`}</Typography>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end mr-20">
          <div className="">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleBacktoList}
            >
              <ChevronLeftOutlinedIcon sx={{ mb: 0.2, mr: 0.5 }} />
              Back
            </Button>
          </div>
        </div>
      </Div>

      <Card sx={{ maxWidth: 800, padding: "12px" }}>
        <CardContent>
          <div>
            <form
              className="h-100"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <div className="">
                <Div sx={{ mt: 0 }}>
                  <Typography className="login_label d-flex items-center g-10">
                    <span>
                      Job Skill <span className="required">*</span>
                    </span>
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="jobskill"
                    value={values.jobskill}
                    placeholder="Enter Job Skill"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.jobskill && Boolean(errors.jobskill)}
                    helperText={touched.jobskill ? errors.jobskill : ""}
                  />
                </Div>
              </div>
              <div className="mt-20">
                <LoadingButton
                  loading={loading}
                  className="primary_btn w-365"
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                >
                  Submit
                </LoadingButton>
              </div>
            </form>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default CreateJobSkill;
