import React from "react";
import Page from "@jumbo/shared/Page";
import Login1 from "app/pages/auth-pages/login1/Login1";
import appRoutes from "./appRoutes";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import ForgotPass from "app/pages/auth-pages/forgot-password/ForgotPassword1";
import SuccessMessage from "app/pages/auth-pages/reset-password/successMessage";
import NotFound from "app/pages/erroe-page/NotFound";

/** routes which you want to make accessible to both authenticated and anonymous users **/
const routesForPublic = [];

/** routes only accessible to authenticated users **/
const routesForAuthenticatedOnly = [...appRoutes];

const routesAdminwithauth = [...appRoutes];

/** routes only accessible when user is anonymous **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/",
    element: (
      <Page component={Login1} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
 /* {
    path: "/admin",
    element: (
      <Page component={Login1} layout={"solo-page"} disableSmLogin={true} />
    ),
  }, */
  {
    path: "/login",
    element: (
      <Page component={Login1} layout={"solo-page"} disableSmLogin={true} />
    ),
  },  

  {
    path: "/forgot-password",
    element: (
      <Page component={ForgotPass} layout={"solo-page"} disableSmLogin={true} />
    ),
  },

  {
    path: `/forgot-password/:token`,
    element: (
      <Page
        component={ResetPassword}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: `/success-message`,
    element: (
      <Page
        component={SuccessMessage}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: `*`,
    element: (
      <Page component={NotFound} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
];

const routesForAdmin = [...appRoutes, ...routesForNotAuthenticatedOnly];
const routesForCurrent = [...routesForNotAuthenticatedOnly];

const routes = [
  ...routesForNotAuthenticatedOnly,
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForAdmin,
  ...routesForCurrent,
  ...routesAdminwithauth,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
  routesForCurrent,
  routesForAdmin,
  routesAdminwithauth,
};
