import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Typography,
  Box,
  Grid,
  Stepper,
  StepIcon,
  StepContent,
  StepLabel,
  CardContent,
  Step,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { config } from "../../../config/main";
import LoaderComp from "app/components/loader/Loader";
import { AuthenticationContext } from "app/utils/constants/AuthContext";
import NodataFound from "app/components/Nodata/NodataFound";
import { ToastServices } from "app/services/toastServices";
import { logoutAction } from "app/redux/actions/authAction";

const View = () => {
  const [loader, setLoader] = useState(false);
  const [tranckloader, setTranckLoader] = useState(true);

  const navigate = useNavigate();

  const handleBacktoList = () => {
    navigate("/manage-enquiry");
  };

  const parms = useParams();

  const [viewdtata, setViewdata] = useState({});

  const [tranking, seTracking] = useState([]);

  const { isAuthenticated } = useContext(AuthenticationContext);

  const [datafound, setDatafound] = useState(false);

  useEffect(() => {
    if (isAuthenticated === true) {
      const fetchData = async () => {
        const token = await Encrytion(sessionStorage.getItem("token"));
        setLoader(true);
        const body = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
        };

        try {
          const response = await fetch(
            `${config.apiUrl}/enquiry/${parms?.id}`,
            body
          );
          if (response.statusCode === 429) {
           ToastServices.ErroToast("Too many requests. waiting before retrying...");
            await new Promise((resolve) => setTimeout(resolve, 3000));
            return fetchData();
          }
          if (response.statusCode === 401) {
            logoutAction()
            navigate('/')
          }
          const data = await response.json();
          if (data?.status === false) {
            setLoader(false);
            setDatafound(true);
          }
       
          setViewdata(data?.data[0]);
          setLoader(false);
        } catch (error) {
          setLoader(false);
           ToastServices.ErroToast("Something went wrong try again")
          
          setDatafound(true);
        }
      };

      const fetchTrack = async () => {
        const token = await Encrytion(sessionStorage.getItem("token"));

        const body = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };

        try {
          const response = await fetch(
            `${config.apiUrl}/enquiry/tracking/${parms?.id}`,
            body
          );
          if (response.statusCode === 429) {
            ToastServices.ErroToast("Too many requests. waiting before retrying...");
            await new Promise((resolve) => setTimeout(resolve, 3000));
            return fetchTrack();
          }
          const data = await response.json();
        
          seTracking(data?.data, "1212");
          setTranckLoader(false);
        } catch (error) {
          setLoader(false);
          ToastServices.ErroToast("Something went wrong try again")
          
        }
      };

      const fetchDataWithDelay = async () => {
        await fetchData();
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await fetchTrack();
      };
      fetchDataWithDelay();
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      {loader === true ? <LoaderComp /> : ""}

      {datafound === false ? (
        <Box>
          <Div className="div_container d-flex justify-content space-between">
            <div className="d-flex justify-content-start flex-start">
              <div className="mt-10">
                <Typography
                  className="heading"
                  variant={"h2"}
                  mb={5}
                  fontWeight={600}
                >{`View Enquiry`}</Typography>
              </div>
            </div>

            <div className="d-flex justify-content-end flex-end mr-20">
              <div className="">
                <Button
                  variant="outlined"
                  className="outlined_btn"
                  onClick={handleBacktoList}
                >
                  <ChevronLeftOutlinedIcon sx={{ mb: 0.2, mr: 0.5 }} />
                  Back
                </Button>
              </div>
            </div>
          </Div>

          <Grid container xs={12} spacing={2}>
            <Grid item xs={7} spacing={2}>
              <Card sx={{ minHeight: "625px" }}>
                <Typography
                  variant={"h4"}
                  m={3}
                  fontWeight={600}
                >{`Enquiry Info`}</Typography>
                <div className="beneficarty_add_details" sx={{ mb: 1 }}>
                  <table className="w-100 beneficary_details">
                    <tr>
                      <td className="table_label">Created On</td>
                      <td className="table_value">
                        {formatDate(viewdtata?.createdAt)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table_label">Enquiry ID</td>
                      <td className="table_value">{viewdtata?.enqId}</td>
                    </tr>
                    <tr>
                      <td className="table_label">Name</td>
                      <td className="table_value">{viewdtata?.name}</td>
                    </tr>
                    <tr>
                      <td className="table_label">Email ID</td>
                      <td className="table_value">{viewdtata?.email}</td>
                    </tr>
                    <tr>
                      <td className="table_label">Mobile</td>
                      <td className="table_value">{viewdtata?.mobileNo}</td>
                    </tr>
                    <tr>
                      <td className="table_label">Company </td>
                      <td className="table_value">{viewdtata?.companyName}</td>
                    </tr>
                    <tr>
                      <td className="table_label">Country</td>
                      <td className="table_value">
                        {viewdtata?.country?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="table_label">Status</td>
                      <td className="table_value">
                        {viewdtata?.status?.id === 4 ? (
                          <span className="p-inactive status">
                            {viewdtata?.status?.statusName}
                          </span>
                        ) : viewdtata?.status?.id === 2 ? (
                          <span className="p-followup status">
                            {viewdtata?.status?.statusName}
                          </span>
                        ) : viewdtata?.status?.id === 1 ? (
                          <span className="p-pending status">
                            {viewdtata?.status?.statusName}
                          </span>
                        ) : viewdtata?.status?.id === 3 ? (
                          <span className="p-active status">
                            {viewdtata?.status?.statusName}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table_label">Message</td>
                      <td className="table_value">{viewdtata?.message}</td>
                    </tr>
                  </table>
                </div>
              </Card>
            </Grid>

            <Grid item xs={5}>
              <Card
                style={{ position: "relative" }}
                sx={{ minHeight: "623px" }}
              >
                {tranckloader === true ? (
                  <div className="order_tracker_loader">
                    <p>Tracking....</p>
                  </div>
                ) : (
                  ""
                )}

                <Typography
                  variant={"h4"}
                  style={{ position: "relative", zIndex: 9 }}
                  m={3}
                  fontWeight={600}
                >{`Track History Info`}</Typography>
                <Div sx={{ ml: 3, marginTop: "2rem", marginBottom: "2rem" }}>
                  <Stepper activeStep={-1} orientation="vertical">
                    {tranking?.map((item, i) => {
                      return (
                        <Step expanded={true} key={item?.id}>
                          <StepLabel StepIconComponent={StepIcon}></StepLabel>
                          <StepContent style={{ width: "100%" }}>
                            <Card className="stepCard new_set_card">
                              <CardContent className="stepContent">
                                <div
                                  className="d-flex items-center"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  {item?.status?.id === 1 ? (
                                    <p className="m-0 enq_titlt">
                                      New Enquiry Created
                                    </p>
                                  ) : (
                                    <p className="m-0 enq_titlt">
                                      {" "}
                                      {item?.notes}{" "}
                                    </p>
                                  )}

                                  {item?.status?.id ===
                                  4 ? (
                                    <span className="p-inactive status ml-10">
                                      {item?.status?.statusName}
                                    </span>
                                  ) : item?.status?.id ===
                                    2 ? (
                                    <span className="p-followup status">
                                      {item?.status?.statusName}
                                    </span>
                                  ) : item?.status?.id === 1 ? (
                                    <span className="p-pending status">
                                      {item?.status?.statusName}
                                    </span>
                                  ) : item?.status?.id ===
                                    3 ? (
                                    <span className="p-active status">
                                      {item?.status?.statusName}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {item?.status?.id === 2 ? (
                                  <span className="followtxt mt-15">
                                   Follow up date: 
                                    {item?.enquiry?.followUpDate}
                                  </span>
                                ) : (
                                  ""
                                )}

                                {item?.status?.id === 1 ? (
                                  <span className="followtxt mt-15">
                                    Created by {item?.enquiry.name} |{" "}
                                    {formatDate(item?.enquiry.createdAt)}
                                  </span>
                                ) : (
                                  <span className="followtxt mt-15">
                                    Updated by admin | 
                                    {formatDate(item?.enquiry.updatedAt)}
                                  </span>
                                )}
                              </CardContent>
                            </Card>
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Div>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <NodataFound />
      )}
    </div>
  );
};

export default React.memo(View);
