import React, { Fragment, useContext, useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import { FaImage } from "react-icons/fa6";
import {
  Switch,
  FormControlLabel,
  CardContent,
  Card,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  Autocomplete,
  Box,
  Drawer,
  Paper,
  Pagination,
  Stack,
  Menu,
  MenuItem,
  Fade,
  Button,
  IconButton,
  Select,
  FormControl,
  InputAdornment,
  FormHelperText
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GetAppIcon from "@mui/icons-material/GetApp";
import CachedIcon from "@mui/icons-material/Cached";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, useFormik } from "formik";
import debounce from "lodash/debounce";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import { AuthenticationContext } from "app/utils/constants/AuthContext";
import SkeletonTableLoader from "./SkeletonLoader";
import NoRecordsFound from "./NoRecordsFound";
import { config } from "app/config/main";
//import UpdateStatus from "./updateStatus";
import LoaderComp from "app/components/loader/Loader";
import { ToastServices } from "app/services/toastServices";
import * as yup from "yup";
import { logoutAction } from "app/redux/actions/authAction";
import MultiValueInput from "./MultiValueComponent";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";

const ManageEventsList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const getToken = sessionStorage.getItem("token");
  const decrpttoken = Encrytion(getToken);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [updateId, setUpdateId] = useState();
  const [eventData, seteventData] = useState({
    status: "",
    toDate: "",
    fromDate: "",
    search: "",
    limit: "10",
    offset: "1",
    sortByType: "",
    sortByKey: "",
  });
  const [country, setCountry] = useState();
  const [status, setStatus] = useState();
  const [sortType, setSortType] = useState("ASC");
  const [sortKey, setSortKey] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [dateopen, setDateOpen] = useState(false);
  const [todateOpen, setTodateOpen] = useState(false);
  const [statusCode, setStatusCode] = useState(false);
  const [error, setError]  = useState(false);
  const loadData = async () => {
    setLoading(true);
    // Merge userData with existing eventData state to retain previous values
    const mergedData = { ...eventData };

    // Construct the API request URL based on the merged data
    const {
      status,
      toDate,
      fromDate,
      search,
      limit,
      offset,
      sortByType,
      sortByKey,
    } = mergedData;
    let api = `${config.apiUrl}/events/list?`;

    // Add parameters to the API request URL based on the provided data
    if (status) api += `&status=[${status}]`;
    if (toDate) api += `&toDate=${toDate}`;
    if (fromDate) api += `&fromDate=${fromDate}`;
    if (search) api += `&searchWith=${search}`;
    if (limit) api += `&limit=${limit}`;
    if (offset) api += `&offset=${offset}`;
    if (sortByType) api += `&sortByType=${sortByType}`;
    if (sortByKey) api += `&sortByKey=${sortByKey}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${decrpttoken}`,
      },
    };

    await fetch(api, options)
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setCount(res?.status ? res.count : 0);
        // setPage(((res?.status)?res.totalPages:0))
        setTotalPage(res?.status ? res.totalPages : 0);
        setLoading(false);

        if (res.statusCode == 404) {
          setLoading(false);
          setError(true)
          
        }

        if (res.statusCode === 401) {
          navigate("/");
          logoutAction();
          setStatusCode(true);
        }
      });
  };
  const loadStatus = async () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch(`${config.apiUrl}/status`, options)
      .then((res) => res.json())
      .then((res) => {
        setStatus(res.data);
      });
  };

 
  const { isAuthenticated } = useContext(AuthenticationContext);
  useEffect(() => {
    const fetchDataWithDelay = async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await loadStatus();
    };
    if (isAuthenticated) {
      fetchDataWithDelay();
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      loadData();
    }
    if (statusCode === true) {
      navigate("/");
      logoutAction();
    }
  }, [eventData]);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function handleDrawerClose() {
    setMobileOpen(false);
  }

  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(i);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

   // change status 

   const handleStatus = async (id , status) => {

    setAnchorEl(null);
    setSelectedIndex(null);

   


  
    const userData = {

      id : id,
      status:status === 1 ? 0 : 1

    }

    
    const options = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${decrpttoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    }

    try {
      await fetch(`${config.apiUrl}/events/status`, options)
        .then((res) => res.json())
        .then((res) => {
         
          if (res?.status === true) {
            setLoading(false);
            ToastServices.SucessToast(res?.message);
            seteventData({ ...eventData,});
           
          } else if (res?.status === false) {
            ToastServices.ErroToast(res?.message);
            setLoading(false);
          } else if (res?.statusCode === 429) {
            setLoading(false);
            ToastServices.ErroToast("Something went wrong");
          } else if (res?.statusCode === 500) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          } else if (res?.statusCode === 404) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          }
        });
    } catch (err) {
      setLoading(false);
      ToastServices.ErroToast("Something went wrong");
      
    }


  
  };
  const handleView = (id) => {
    navigate(`/manage-events/view/${id}`);
    handleClose();
  };

  const handleManageGallery = (id) => {
    navigate(`/manage-events/manage-gallary/${id}`);
  };

  const handleUpdateEvent = (id) => {
    navigate(`/manage-events/update/${id}`);
  };

  const handleViewEvent = (id) => {
    navigate(`/manage-events/view/${id}`);
  };

 

  // search 

  const debouncedSearch = debounce((input) => {
    setLoading(true);
    const lowerCaseInput = input.toLowerCase();
    seteventData({ ...eventData, search: input });
    
    setLoading(false);
  }, 3000);


  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);

    let input = event.target.value.toLowerCase();
    
    debouncedSearch(input);
  };

  const handleClearSearch = () => {
    if (searchInput != "") {
      setSearchInput("");
      // Clear the input value in the DOM
      const inputElement = document.querySelector(".search input");
      if (inputElement) {
        inputElement.value = "";
      }
      seteventData({ ...eventData, search: "" });
    }
  };

// sorting 

const handleSort = (column) => {
  const newSorttype =
    sortKey === column && sortType === "ASC" ? "DESC" : "ASC";
  setSortType(newSorttype);
  setSortKey(column);
  seteventData({
    ...eventData,
    sortByType: newSorttype,
    sortByKey: column,
    // offset: 1,
  });
};

//filter
  function handleFilterToggle() {
    setFilterOpen(!filterOpen);
  }
  function handleFilterClose() {
    setFilterOpen(false);
  }
  const handleClear = () => {
    const initialValues = formik.initialValues;
    const currentValues = formik.values;

    // Check if any of the current values differ from the initial values
    const shouldReset = Object.keys(currentValues).some(
      (key) => currentValues[key] !== initialValues[key]
    );

    // Reset formik values only if they differ from initial values
    if (shouldReset) {
      formik.resetForm();
      handleReset();
      setSearchInput("");
      const inputElement = document.querySelector(".search input");
      if (inputElement) {
        inputElement.value = "";
      }
    }
    handleFilterClose();
    //  ToastServices.SucessToast("Filter reset successfully")
  };
  const handleFilter = () => {
    handleFilterToggle();
    handleClose();
  };

  const validationSchema = yup.object().shape({
    fromDate: yup
      .date()
      .nullable()
      // .test(
      //   "fromDate-and-toDate",
      //   "Both from date and to date are required. if either is provided",
      //   function (value) {
      //     const { toDate } = this.parent;
      //     return !value || (value && toDate);
      //   }
      // )
      .test(
        "fromDate-required-if-toDate",
        "From date is required",
        function (fromDate) {
          const { toDate } = this.parent;
          return !toDate || (toDate && fromDate);
        }
      )
      
      ,
    toDate: yup
      .date()
      .nullable()
      .when('fromDate', (fromDate, schema) => {
        if (fromDate) {
          return schema
            .required('To Date is required')
            .test(
              "toDate-after-fromDate",
              "To date cannot be before From Date",
              function (toDate) {
                return moment(toDate).isSameOrAfter(fromDate, 'day');
              }
            );
        } else {
          return schema
           
        }
      }),
    status: yup.string().nullable(), 
  });
  


  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      status: "",
      toDate: null,
      fromDate: null,
    },

    onSubmit: async (values) => {
      handleSubmit(values);
      handleFilterClose();
    },
  });

  const handleSubmit = (values) => {
    setisLoading(true);
    let arr = values?.country ? [values?.country] : [];

    const userData = {
      status: values?.status ? values?.status : "",
      toDate: !values?.toDate ? "" :  moment(values?.toDate).format("YYYY-MM-DD"),
      fromDate: !values?.fromDate ? "" : moment(values?.fromDate).format("YYYY-MM-DD"),
    };
  
   
    const hasNonEmptyValues = Object.values(userData).some(value => value !== "");
  
    setFilterData(userData);
  
    if (hasNonEmptyValues) {
      seteventData({ ...eventData, ...userData });
      ToastServices.SucessToast("Data filtered successfully");
    }
  
    setisLoading(false);
  };

  //Reset
  const handleReset = () => {
    formik.resetForm();
    seteventData({
      status: "",
      toDate: "",
      fromDate: "",
      search: "",
      limit: "",
      offset: "",
      sortByType: "",
      sortByKey: "",
    });
    setSearchInput("");
    // Clear the input value in the DOM
    const inputElement = document.querySelector(".search input");
    if (inputElement) {
      inputElement.value = "";
    }
    ToastServices.SucessToast("Filter reset successfully");
  };



 
  const handlePaginationChange = (event, page) => {
    setPage(page);
    seteventData({ ...eventData, offset: page });
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
    seteventData({ ...eventData, limit: event.target.value, offset: 1 });
  };

  const handleChangeToggle = (event) => {
    const { name, value } = event.target;

    if (name === "status") {
      setSelectedStatus(value);
    } else if (name === "country") {
      setSelectedCountry(value);
    }
    formik.handleChange(event);
  };

  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
    // delete 

    const handleDelete = async (id, ) => {

      setAnchorEl(null);
      setSelectedIndex(null);
  
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
  
          deleteAction(id)
  
        }
      });
    }
  
    const deleteAction = async (id) =>{
  
      const userData = {
        id:id
      }
  
      const options = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${decrpttoken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      }
      try {
        await fetch(`${config.apiUrl}/events/delete/${id}`, options)
          .then((res) => res.json())
          .then((res) => {
           
            if (res?.status === true) {
              setLoading(false);
              Swal.fire({
                title: "Deleted!",
                text: "Your item has been deleted.",
                icon: "success"
              }).then(()=>{
                seteventData({ ...eventData,});
                
              })
            } else if (res?.status === false) {
              ToastServices.ErroToast(res?.message);
              setLoading(false);
            } else if (res?.statusCode === 429) {
              setLoading(false);
              ToastServices.ErroToast("Something went wrong");
            } else if (res?.statusCode === 500) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            } else if (res?.statusCode === 404) {
              ToastServices.ErroToast("Something went wrong");
              setLoading(false);
            }
          });
      } catch (err) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
       
      }
  
    }
  const handleCreate = () => {
    navigate("/manage-events/create");
  };
  const handleUpdate = (id) => {
    navigate(`/manage-events/update/${id}`);
  };

  return (
    <Fragment>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >Events ({data?.length})</Typography>
          </div>

          <div className="">
            {/** <SearchGlobal loadData={loadData} data={data} /> */}
            <Search className="search">
              <SearchIconWrapper>
                <SearchIcon className="search-icon" />
              </SearchIconWrapper>

              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
                endAdornment={
                  searchInput && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon fontSize="12" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Search>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end">
          <div className="ml-20 ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleCreate}
            >
              <AddIcon className="mr-5" />
              Create
            </Button>
          </div>
          <div className="ml-20 ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleFilter}
            >
              <FilterListIcon className="mr-5" />
              Filter
            </Button>
          </div>
          <div className="ml-20  ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleReset}
            >
              <CachedIcon className="mr-5" />
              Reset
            </Button>
          </div>
          
        </div>
      </Div>
      <Card className="responsive_card">
        <CardContent>
          <Div>
            <Table className="common_table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "eventDate"}
                      direction={sortType}
                      onClick={() => handleSort("eventDate")}
                    >
                     Event Date
                    </TableSortLabel>
                  </TableCell>
                  
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "eventTitle"}
                      direction={sortType}
                      onClick={() => handleSort("eventTitle")}
                    >
                     Event Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "eventLocation"}
                      direction={sortType}
                      onClick={() => handleSort("eventLocation")}
                    >
                     Location
                    </TableSortLabel>
                  </TableCell>
                 
                  {/* <TableCell>
                    
                     Image
                 
                  </TableCell> */}
                  <TableCell>
                      Status
                  </TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <>
                    <LoaderComp />
                    
                  </>
                ) 
              }
                
                {
                  data?.map((item, i) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {moment(item?.eventDate).format("DD/MM/YYYY")}
                        </TableCell>
                        
                        <TableCell>
                        <span className="link" onClick={() => handleView(item?.id)}>
                          {item?.eventTitle?.length > 50? (
                            <>
                             <Tooltip title={item?.eventTitle}>
                              {item?.eventTitle?.substring(0, 50)}...
                              </Tooltip>
                            </>
                          ) : item?.eventTitle}
                        </span>
                          </TableCell>
                        <TableCell>
                          {item?.eventLocation }
                        </TableCell>
                        <TableCell>
                        {item?.status === 1 ? (
                            <p className="p-active status">Active</p>
                          ) : (
                            <p className="p-inactive status">In-Active</p>
                          )}
                        </TableCell>

                        <TableCell>
                          <IconButton
                          className="p-0"
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          
                          <Tooltip title={"Manage Gallery"}>
                          <IconButton onClick={() => handleManageGallery(item?.id)}>
        <FaImage />
      </IconButton>
                          
                              </Tooltip> 
                         
                          
                        </TableCell>
                        <Menu
                          className="custom_popover"
                          id="fade-menu"
                          MenuListProps={{
                            "aria-labelledby": "fade-button",
                          }}
                          open={selectedIndex === i}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                           <MenuItem onClick={() => handleViewEvent(item?.id)}>
                            View
                          </MenuItem>
                          <MenuItem onClick={() => handleUpdateEvent(item?.id)}>
                            Update
                          </MenuItem>
                          <MenuItem  onClick={() => handleStatus(item?.id, item?.status)}>
                            {item?.status === 1
                              ? "Mark as Inactive"
                              : "Mark as Active"}
                          </MenuItem>
                          <MenuItem onClick={()=> handleDelete(item?.id)}>
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableRow>
                    );
                  })
                }
                  { error &&   <NoRecordsFound tablecell={7} />}
                  {data?.length === 0 && ( <NoRecordsFound tablecell={7} />)}
              </TableBody>
            </Table>

            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <div className="count_page">
                <span className="mr-10">Items per page :</span>
                <select
                  className="pagination_selectbox"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value="10">10</option>
                  <option value="25">25 </option>
                  <option value="50">50 </option>
                </select>
              </div>
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handlePaginationChange}
                />
              </Stack>
            </div>
          </Div>
        </CardContent>
      </Card>

      <div>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={filterOpen}
          onClose={handleFilterToggle}
        >
          <Box sx={{ width: "400px" }}>
            <div className="sidebar_header">
              <h1> Filter </h1>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={handleFilterClose}
              />
            </div>

            <div className="sideform_container">
              <form onSubmit={formik.handleSubmit}>
                <Div>
                  <Typography className="from_label">From Date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      name="fromDate"
                      className= { formik.touched.fromDate && formik.errors.fromDate ? "form_new_style date_error"  : 'form_new_style'  } 
                      value={formik.values.fromDate}
                      open={dateopen}
                      slotProps={{
                        textField: { onClick: () => setDateOpen(true) },
                      }}
                      onClose={() => setDateOpen(false)}
                      onChange={(newDate, event) => {
                        setFromDate(newDate);
                        formik.setFieldValue("fromDate", newDate);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      
                      format="dd/MM/yyy"
                    />
                    {formik.touched.fromDate && formik.errors.fromDate ? (
                      <Typography color="error">
                         <FormHelperText>
                         {formik.errors.fromDate}
                         </FormHelperText>
                        
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">To Date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                       className= { formik.touched.toDate && formik.errors.toDate ? "form_new_style date_error"  : 'form_new_style'  } 
                      
                      name="toDate"
                      value={formik.values.toDate}
                      open={todateOpen}
                      slotProps={{
                        textField: { onClick: () => setTodateOpen(true) },
                      }}
                      onClose={() => setTodateOpen(false)}
                      onChange={(newDate, event) => {
                        setToDate(newDate);
                        formik.setFieldValue("toDate", newDate);
                      }}
                      
                      format="dd/MM/yyy"
                      minDate={formik.values.fromDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {formik.touched.toDate && formik.errors.toDate ? (
                      <Typography color="error">
                        <FormHelperText>
                        {formik.errors.toDate}
                        </FormHelperText>
                        
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">Status</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      style={{ width: "100%" }}
                      displayEmpty
                      MenuProps={{
                        classes: { paper: "" }, // Assign a class to the dropdown menu
                      }}
                      renderValue={
                        formik.values.status !== ""
                          ? undefined
                          : () => "Select Status"
                      }
                    >  
                    <MenuItem value={"1"}>Active</MenuItem>
                    <MenuItem value={"0"}>Inactive</MenuItem>
                     
                      
                    </Select>
                    {formik.touched.status && formik.errors.status ? (
                      <Typography color="error">
                        {formik.errors.status}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Div>

              

                <Div
                  sx={{
                    mt: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LoadingButton
                    loading={loading}
                    className="filter_bttn"
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ marginRight: "10px" }}
                  >
                    Filter
                  </LoadingButton>

                  <Button
                    className="clear_btn"
                    onClick={handleClear}
                    variant="contained"
                    fullWidth
                    style={{ marginLeft: "10px" }}
                  >
                    Clear
                  </Button>
                </Div>

                <Div></Div>
              </form>
            </div>
          </Box>
        </Drawer>
      </div>
    </Fragment>
  );
};

export default ManageEventsList;
