import CryptoJS  from 'crypto-js';

let key = "5TGB&YHN7UJM(IK<5TGB&YHN7UJM(IK<";
let iv = "!QAZ2WSX#EDC4RFV"

export const Encrytion = (ciphertext) => {
  
  // console.log(ciphertext , "ciphertext" )

  let _key = CryptoJS.enc.Utf8.parse(key);
  let _iv = CryptoJS.enc.Utf8.parse(iv);
  let encryptData = CryptoJS.AES.encrypt(
    ciphertext, _key, {
    keySize: 128 / 8,
    iv: _iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  return encryptData ? encryptData : ciphertext;

}


export const NewdeCrypt = (ciphertext) => {
  
  let _key = CryptoJS.enc.Utf8.parse(key);
  let _iv = CryptoJS.enc.Utf8.parse(iv);
  let decryptedBytes = CryptoJS.AES.decrypt(ciphertext, _key, {
    keySize: 128 / 8,
    iv: _iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  let decryptData = decryptedBytes.toString(CryptoJS.enc.Utf8);
  if (decryptedBytes.sigBytes > 0) {
    return decryptData;
  } else {
    return ciphertext;
  }
}
