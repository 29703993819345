import React, { useEffect, useState } from 'react';
import {Search, SearchIconWrapper, StyledInputBase} from "./style";
import SearchIcon from "@mui/icons-material/Search";
import debounce from 'lodash/debounce';

const SearchGlobal = ({sx, loadData, EnqData}) => {
   
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(false);
    const debouncedLoadData = debounce(loadData, 500); 
    const debouncedFilter = debounce((input) => {
        setLoading(true)
        const lowerCaseInput = input.toLowerCase();
        
        console.log(lowerCaseInput, 'debounced input')
        const userData = {
           // country:'',
           // status:'',
           // toDate:'',
           // fromDate:'',
            search:lowerCaseInput,
           // limit:'',
           // offset:'',
          };
          debouncedLoadData(userData)
      
        
       
        
        
        setLoading(false);
         
      }, 3000); 
  
     
       const handleSearchChange = (event) => {
         setSearchInput(event.target.value);
        //console.log(event.target.value, 'Search Value')
         setLoading(true)
         debouncedFilter(event.target.value);
         
       };
       // Update filtered data when searchInput changes
       useEffect(() => {
         debouncedFilter(searchInput);
         return () => {
           // Cleanup the debounce function on component unmount
           debouncedFilter.cancel();
         };
       }, [searchInput, debouncedFilter]);
    return (
        <Search sx={sx} className='search'>
            <SearchIconWrapper>
                <SearchIcon className='search-icon'/>
            </SearchIconWrapper>

            <StyledInputBase
                placeholder="Search"
                inputProps={{'aria-label': 'search'}}
                onChange={handleSearchChange}
            />
        </Search>
    );
};

export default SearchGlobal;
