import { store } from "../store";
import jwtDecode from "jwt-decode";
import { SET_USER_DATA, SET_PERMISSION, SET_USER } from "app/utils/constants/appActions";

export const userJwtData = (data) => {
   // console.log(data, "............")
    const decoded = jwtDecode(data);
    sessionStorage.setItem('portal', decoded.portal)
    sessionStorage.setItem('rollId', decoded.role_id)
    store.dispatch({
        type: SET_USER_DATA,
        payload: decoded
    })
}

export const setEmptyUser = () => {
    store.dispatch({
        type: SET_USER
    })
}