
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import Div from "@jumbo/shared/Div";
import { Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import moment from "moment";
import MultiSelect from "multiselect-react-dropdown";
import ReactQuill from "react-quill";
import { config } from "app/config/main";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import MultiValueInput from "./MultiValueComponent";
import 'react-quill/dist/quill.snow.css';

const JobCreate = () => {
  const [category, setCategory] = useState([]);
  const [skill, setSkill] = useState([]);
  const [career, setCareer] = useState([]);
  const [jobTime, setJobTime] = useState([]);
  const [degree, setDegree] = useState([]);
  const [todateOpen, setTodateOpen] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [chips, setChips] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const regexString = "^\\S$|^\\S[\\s\\S]*\\S$";

  const validationSchema = yup.object({
    jobTitle: yup.string().matches(new RegExp(regexString), "Please provide valid job title").required("Job title is required"),
    jobCategory: yup
      .string()
      .notOneOf(["120000"], "Job category is required")
      .required("Job category is required"),
    jobSkill: yup
      .array()
      .min(1, "Select at least one job skill")
      .required("Job skill is required"),
    gender: yup
      .string()
      .notOneOf(["120000"], "Gender is required")
      .required("Gender is required"),
    fromSalary: yup
      .number()
      .required("From salary is required")
      .min(0.1, "From salary must be greater than 0"),
    toSalary: yup
      .number()
      .required("To salary is required")
      .min(0.1, "To salary must be greater than 0")
      .when("fromSalary", (fromSalary, schema) =>
        fromSalary
          ? schema.min(fromSalary, "To salary cannot be lower than from salary")
          : schema
      ),
    jobExpiryDate: yup
      .date()
      .required("Job expiry date is required")
      .nullable(),
    country: yup
      .string()
      .notOneOf(["120000"], "Country  is required")
      .required("Country  is required"),
    state: yup
      .string()
      .notOneOf(["120000"], "State  is required")
      .required("State  is required"),
    city: yup
      .string()
      .notOneOf(["120000"], "City  is required")
      .required("City  is required"),
    careerLevel: yup
      .string()
      .notOneOf(["120000"], "career level  is required")
      .required("Career level  is required"),
    jobShift: yup
      .string()
      .notOneOf(["120000"], "Job shift  is required")
      .required("job shift  is required"),
    jobTag: yup
      .array()
      .of(yup.string().required(""))
      .min(1, "Job tag is required"),
    DegreeLevel: yup
      .string()
      .notOneOf(["120000"], "Degree level  is required")
      .required("Degree level  is required"),
    positions: yup.number().required("Number of positions is required").min(0.1, "Number of positions must be greater than 0"),
    experience: yup.number().required("Experience is required").min(0, "Experience must be greater than or equal to 0"),
    keyResponsibilities: yup
      .string().test(
        'is-not-empty',
        'Key responsibilities',
        value => value && value.replace(/<[^>]*>?/gm, '').trim().length > 0
      )
      .test(
        'min-length',
        'Key responsibilities must be at least 50 characters',
        value => value && value.replace(/<[^>]*>?/gm, '').trim().length >= 50
      )
      .required('Description is required'),
    Description: yup.string().test(
      'is-not-empty',
      'Description is required',
      value => value && value.replace(/<[^>]*>?/gm, '').trim().length > 0
    )
    .test(
      'min-length',
      'Description must be at least 50 characters',
      value => value && value.replace(/<[^>]*>?/gm, '').trim().length >= 50
    )
    .required('Description is required')
  });

  const initialValues = {
    jobTitle: "",
    jobCategory: "",
    jobSkill: [],
    gender: "",
    fromSalary: "",
    toSalary: "",
    jobExpiryDate: null,
    country: "",
    state: "",
    city: "",
    careerLevel: "",
    jobShift: "",
    jobTag: [],
    DegreeLevel: "",
    positions: "",
    experience: "",
    keyResponsibilities: "",
    Description: "",
    hideSalary: true,
    freeLance: true,
  };

  const gender = [
    { value: "MALE", genderName: "Male" },
    { value: "FEMALE", genderName: "Female" },
    { value: "BOTH", genderName: "Both" },
  ];

  const EncryptTOken = Encrytion(sessionStorage.getItem("token"));

  //   get depency api

  const getCategories = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `${config.apiUrl}/job-category/raw_categories?status=0`,
        { headers }
      );

      setCategory(response?.data?.data);
    } catch (e) {}
  };

  // get Skill

  const getSkill = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const getresponse = await axios.get(
        `${config.apiUrl}/job-skills/raw_skills?status=0`,
        { headers }
      );
      setSkill(getresponse?.data?.data);
    } catch (e) {}
  };

  const getCareer = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${config.apiUrl}/career-level/list`, {
        headers,
      });
      setCareer(response?.data?.data);
    } catch (e) {}
  };

  const getJobLevel = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${config.apiUrl}/job-shift/list`, {
        headers,
      });

      setJobTime(response?.data?.data);
    } catch (e) {}
  };

  const getDegree = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `${config.apiUrl}/degree-level/raw_degree?status=0`,
        { headers }
      );
      setDegree(response?.data?.data);
    } catch (e) {}
  };

  const fetchCountries = async () => {
    try {
      const getresponseCountry = await axios.get(`${config.apiUrl}/country`);

      setCountries(getresponseCountry?.data?.data);
    } catch (e) {}
  };

  const loadState = async (stateId) => {
    try {
      const getresponseState = await axios.get(
        `${config.apiUrl}/state/${stateId}`
      );
      setStates(getresponseState?.data?.data);
    } catch (e) {}
  };

  const loadCity = async (cityId) => {
    try {
      const getresponseCity = await axios.get(
        `${config.apiUrl}/district/${cityId}`
      );
      setCities(getresponseCity?.data?.data);
    } catch (e) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCategories();
        await getSkill();
        await fetchCountries();
        await getCareer();
        await getJobLevel();
        await getDegree();
      } catch (error) {}
    };
    fetchData();
    return () => {};
  }, []);

  const postJobData = async (values) => {
    const userData = {
      jobTitle: values?.jobTitle,
      gender: values?.gender,
      salaryFrom: values?.fromSalary,
      salaryTo: values?.toSalary,
      jobExpiryDate:moment(values?.jobExpiryDate).toISOString(),
      noOfPositions: values?.positions,
      description: values?.Description,
      keyResponsibility: values?.keyResponsibilities,
      experience: values?.experience,
      postingDate: moment().format("DD/MM/YYYY"),
      hideSalary: values?.hideSalary,
      isFreelance: values?.freeLance,
      jobTag: values?.jobTag,
      categoryId: values?.jobCategory,
      skillsId: values?.jobSkill,
      countryId: values?.country,
      stateId: values?.state,
      districtId: values?.city,
      carrierLevelId: values?.careerLevel,
      jobShiftId: values?.jobShift,
      degreeLevelId: values?.DegreeLevel,
    };
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const postResponse = await axios.post(`${config.apiUrl}/jobs`, userData, {
        headers,
      });
      if (postResponse?.data?.status === true) {
        setLoading(false);
        ToastServices.SucessToast(postResponse?.data?.message);
        navigate('/manage-job')
      } else if (postResponse?.data?.status === false) {
        ToastServices.ErroToast(postResponse?.data?.message);
        setLoading(false);
      } else if (postResponse?.data?.statusCode === 429) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
      } else if (postResponse?.data?.statusCode === 500) {
        ToastServices.ErroToast("Something went wrong");
        setLoading(false);
      } else if (postResponse?.data?.statusCode === 404) {
        ToastServices.ErroToast("Something went wrong");
        setLoading(false);
      }
    } catch (e) {}
  };

  const handlekeydown = (evt) =>{
    ["e", "E", "+", "-",  "ctrlKey", "vKey"].includes(evt.key) &&evt.preventDefault();
    if (evt.keyCode === 38 || evt.keyCode === 40 || evt.keyCode === 17 || evt.keyCode === 86) {
      evt.preventDefault();
    }
    
    }
    const handlekeydownPos = (evt) =>{
      ["e", "E", "+", "-", ".",  "ctrlKey", "vKey"].includes(evt.key) &&evt.preventDefault();
      if (evt.keyCode === 38 || evt.keyCode === 40 || evt.keyCode === 17 || evt.keyCode === 86) {
        evt.preventDefault();
      }
    }


  const handleBacktoList = () => {
    navigate("/manage-job");
  };

  return (
    <div>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >{`Create Job`}</Typography>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end mr-20">
          <div className="">
            <Link to={'/manage-job'}>
          
            <Button variant="outlined" className="outlined_btn">
              <ChevronLeftOutlinedIcon
                sx={{ mb: 0.2, mr: 0.5 }}
                
              />
              Back
            </Button>
            </Link>
          </div>
        </div>
      </Div>
      <Card sx={{ padding: "12px" }}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            //   onSubmit={handleSubmit}
            onSubmit={(values) => {
              postJobData(values);
            }}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              setFieldTouched,
            }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Title <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      value={values.jobTitle}
                      name="jobTitle"
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      error={touched.jobTitle && Boolean(errors.jobTitle)}
                      helperText={touched.jobTitle && errors.jobTitle}
                      placeholder="Enter Job Title"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Category <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.jobCategory && Boolean(errors.jobCategory)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="jobCategory"
                        value={values.jobCategory}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.jobCategory !== ""
                            ? undefined
                            : () => "Select Job Category"
                        }
                      >
                        {category?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.categoryName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.jobCategory && errors.jobCategory && (
                        <FormHelperText>{errors.jobCategory}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Skill <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.jobSkill && Boolean(errors.jobSkill)}
                    >
                      <MultiSelect
                      showCheckbox={true}
                        className={
                          touched.jobSkill && errors.jobSkill && "border_red"
                        }
                        options={skill}
                        selectedValues={skill.filter((skill) =>
                          values.jobSkill.includes(skill.id)
                        )}
                        onSelect={(selectedList) => {
                          setFieldValue(
                            "jobSkill",
                            selectedList.map((item) => item.id)
                          );
                          setFieldTouched("jobSkill", false);
                        }}
                        onRemove={(selectedList) => {
                          setFieldValue(
                            "jobSkill",
                            selectedList.map((item) => item.id)
                          );
                          setFieldTouched("jobSkill", false);
                        }}
                        displayValue="skillName"
                        placeholder="Select Job Skills"
                      />

                      {touched.jobSkill && errors.jobSkill && (
                        <FormHelperText>{errors.jobSkill}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Gender <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.gender && Boolean(errors.gender)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.gender !== ""
                            ? undefined
                            : () => "Select Gender"
                        }
                      >
                        {gender?.map((item) => {
                          return (
                            <MenuItem value={item?.value}>
                              {item?.genderName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.gender && errors.gender && (
                        <FormHelperText>{errors.gender}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Salary From (CTC in Lakhs){" "}
                        <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydown(evt)}
                      value={values.fromSalary}
                      name="fromSalary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fromSalary && Boolean(errors.fromSalary)}
                      helperText={touched.fromSalary && errors.fromSalary}
                      placeholder="Enter Salary From CTC (in Lakhs)"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Salary To (CTC in Lakhs){" "}
                        <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydown(evt)}
                      value={values.toSalary}
                      name="toSalary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.toSalary && Boolean(errors.toSalary)}
                      helperText={touched.toSalary && errors.toSalary}
                      placeholder="Enter Salary To (CTC in Lakhs)"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Expiry Date <span className="required">*</span>
                      </span>
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className= { touched.jobExpiryDate && errors.jobExpiryDate ? "form_new_style date_error"  : 'form_new_style'  } 
                        name="jobExpiryDate"
                        value={values.jobExpiryDate}
                        open={todateOpen}
                        onBlur={handleBlur}
                        slotProps={{
                          textField: { onClick: () => setTodateOpen(true) },
                        }}
                        onClose={() => setTodateOpen(false)}
                        onChange={(newDate, event) => {
                          setToDate(newDate);
                          setFieldValue("jobExpiryDate", newDate);
                        }}
                        disablePast
                        format="dd/MM/yyy"
                        renderInput={(params) => (
                          <TextField onBlur={handleBlur} {...params} />
                        )}
                      />
                      {touched.jobExpiryDate && errors.jobExpiryDate ? (
                        <Typography color="error">
                          <FormHelperText>
                            {errors.jobExpiryDate}
                          </FormHelperText>
                        </Typography>
                      ) : null}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        {" "}
                        Country <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.country && Boolean(errors.country)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="country"
                        value={values.country}
                        onChange={async (event) => {
                          const selectedCountryId = event.target.value;
                          setFieldValue("country", selectedCountryId);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          touched.country = null;
                          errors.country = false;

                          if (selectedCountryId) {
                            loadState(selectedCountryId);
                          }
                        }}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.country !== ""
                            ? undefined
                            : () => "Select Country"
                        }
                      >
                        {countries?.map((item) => {
                          return (
                            <MenuItem value={item?.countryId}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.country && errors.country && (
                        <FormHelperText>{errors.country}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        {" "}
                        State <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.state && Boolean(errors.state)}
                    >
                      <Select
                        className={
                          touched.state && errors.state && "custom_error"
                        }
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="state"
                        value={values.state}
                        onChange={async (event) => {
                          const selectedStateID = event.target.value;
                          setFieldValue("state", selectedStateID);
                          setFieldValue("city", "");
                          touched.state = null;
                          errors.state = false;

                          if (selectedStateID) {
                            loadCity(selectedStateID);
                          }
                        }}
                        disabled={!values.country}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.state !== "" ? undefined : () => "Select State"
                        }
                      >
                        {states?.map((item) => {
                          return (
                            <MenuItem value={item?.stateId}>
                              {item?.stateName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.state && errors.state && (
                        <FormHelperText className="required">
                          {errors.state}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        {" "}
                        City <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.city && Boolean(errors.city)}
                    >
                      <Select
                        className={
                          touched.city && errors.city && "custom_error"
                        }
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="city"
                        value={values.city}
                        onChange={async (event) => {
                          const cityID = event.target.value;

                          setFieldValue("city", cityID);
                          touched.city = null;
                          errors.city = false;
                        }}
                        disabled={!values.country || !values.state}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.city !== "" ? undefined : () => "Select city"
                        }
                      >
                        {cities?.map((item) => {
                          return (
                            <MenuItem value={item?.districtId}>
                              {item?.districtName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.city && errors.city && (
                        <FormHelperText className="required">
                          {errors.city}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Career Level <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.careerLevel && Boolean(errors.careerLevel)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="careerLevel"
                        value={values.careerLevel}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.careerLevel !== ""
                            ? undefined
                            : () => "Select Career Level"
                        }
                      >
                        {career?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.careerName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.careerLevel && errors.careerLevel && (
                        <FormHelperText>{errors.careerLevel}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Shift <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.jobShift && Boolean(errors.jobShift)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="jobShift"
                        value={values.jobShift}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.jobShift !== ""
                            ? undefined
                            : () => "Select Job Shift"
                        }
                      >
                        {jobTime?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.shiftName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.jobShift && errors.jobShift && (
                        <FormHelperText>{errors.jobShift}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Tag <span className="required">*</span>
                      </span>
                    </Typography>
                    <FieldArray name="jobTag">
                      {({ push, remove }) => (
                        <>
                          <MultiValueInput
                            values={values.jobTag}
                            onAdd={(value) => push(value)}
                            onDelete={(index) => remove(index)}
                            error={errors.jobTag}
                            touched={touched.jobTag}
                          />
                          {touched.jobTag && errors.jobTag && (
                            <FormHelperText className="required">
                              {errors.jobTag}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Degree Level <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.DegreeLevel && Boolean(errors.DegreeLevel)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="DegreeLevel"
                        value={values.DegreeLevel}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.DegreeLevel !== ""
                            ? undefined
                            : () => "Select Degree Level"
                        }
                      >
                        {degree?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.degreeName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.DegreeLevel && errors.DegreeLevel && (
                        <FormHelperText>{errors.DegreeLevel}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                      No. of Positions <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydownPos(evt)}
                      value={values.positions}
                      name="positions"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.positions && Boolean(errors.positions)}
                      helperText={touched.positions && errors.positions}
                      placeholder="Enter No. of Positions"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                      Job Experience (In Years) <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydown(evt)}
                      value={values.experience}
                      name="experience"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.experience && Boolean(errors.experience)}
                      helperText={touched.experience && errors.experience}
                      placeholder="Enter Job Experience"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Key Responsibilities <span className="required">*</span>
                      </span>
                    </Typography>

                    <Field name="keyResponsibilities">
                      {({ field }) => (
                        <ReactQuill
                          theme="snow"
                          value={field.value}
                          onChange={(html) => {
                            setFieldValue(field.name, html);
                          }}
                          onBlur={() => {
                            setFieldTouched(field.name, true);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: ['Inter'] }],
                              [{ size: ['small', false, 'large', 'huge'] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                              ],
                              ["link", ],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                           
                          ]}
                          style={{ height: "230px" }}
                        />
                      )}
                    </Field>

                    {touched.keyResponsibilities &&
                      errors.keyResponsibilities && (
                        <FormHelperText>
                          {errors.keyResponsibilities}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Description <span className="required">*</span>
                      </span>
                    </Typography>

                    <Field name="Description">
                      {({ field }) => (
                        <ReactQuill
                          theme="snow"
                          value={field.value}
                          onChange={(html) => {
                            setFieldValue(field.name, html);
                          }}
                          onBlur={() => {
                            setFieldTouched(field.name, true);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: ['Inter'] }],
                              [{ size: ['small', false, 'large', 'huge'] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                              ],
                              ["link", ],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                          
                          ]}
                          style={{ height: "230px" }}
                        />
                      )}
                    </Field>

                    {touched.Description && errors.Description && (
                      <FormHelperText>{errors.Description}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>Hide Salary</span>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.hideSalary}
                          onChange={handleChange}
                          name="hideSalary"
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>Freelance</span>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.freeLance}
                          onChange={handleChange}
                          name="freeLance"
                        />
                      }
                    />
                  </Grid>

                  <Grid item md={12}>
                    <LoadingButton
                      loading={loading}
                      className="primary_btn w-365"
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

export default JobCreate;

