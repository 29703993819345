
import React, {  useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import Div from "@jumbo/shared/Div";
import { Field,  Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import moment from "moment";
import ReactQuill from "react-quill";
import { IoAdd, IoClose } from "react-icons/io5";
import { config } from "app/config/main";
import { Encrytion } from "app/utils/EncryptionHelpers";
import LoaderComp from "app/components/loader/Loader";
import { ToastServices } from "app/services/toastServices";
import 'react-quill/dist/quill.snow.css';

const CreateEvents = () => {

  const EncryptTOken = Encrytion(sessionStorage.getItem("token"));
  const [todateOpen, setTodateOpen] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bannerImg, setBannerImg] = useState(null); 
  const thumnailRef = useRef(null); 
  const BannerRef = useRef(null); 
  const [thumb, seThumb] = useState(null);
  const [pageloader, setPageLoader] = useState(false)
  const [thumbLoader, SetThumLoader] = useState(0)
  const [bannerLoader, setBannerLoader] = useState(false)

  const navigate = useNavigate();
  const regexString = "^\\S$|^\\S[\\s\\S]*\\S$";
  const validationSchema = yup.object({
    eventTitle: yup.string()
    .matches(new RegExp(regexString), "Please provide valid event title")
    .max(100, 'Event title should not exceed  100 characters')
    .required("Event title is required"),
    eventDate: yup.date().required("Event Date is required").nullable(),
    location: yup.string().matches(new RegExp(regexString), "Please provide valid location").required("Location is required"),
    Description: yup.string().test(
      'is-not-empty',
      'Description is required',
      value => value && value.replace(/<[^>]*>?/gm, '').trim().length > 0
    )
    .test(
      'min-length',
      'Description must be at least 50 characters',
      value => value && value.replace(/<[^>]*>?/gm, '').trim().length >= 50
    )
    .required('Description is required'),
    thumbnail: yup.mixed().required("Thumbnail image is required"),
    banner: yup.mixed().required("Banner image is required")
  });

  const initialValues = {
    eventTitle: "",
    eventDate: null,
    location: "",
    Description: "",
    thumbnail: "",
    banner:""
  };

  const handleFileChange = async (event, setFieldValue,) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      const validFileExtensions = ["jpeg", "jpg", "png"];
      const maxFileSize = 50 * 1024 * 1024;

      if (validFileTypes.includes(fileType) && validFileExtensions.includes(fileExtension)) {
        if (file.size <= maxFileSize) {
          const img = new Image();
          img.onload = async () => {
            const aspectRatio = img.width / img.height;
            const isValidAspectRatio = Math.abs(aspectRatio - (3 / 2)) < 0.01;

            if (isValidAspectRatio) {

                const formData = new FormData();
                formData.append("file", file);
      
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${config.apiUrl}/upload`, true);
      
                
                xhr.upload.onprogress = (event) => {
                  if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    SetThumLoader(percent);
                   
                  }
                };
      
                xhr.onload = async () => {
                  if (xhr.status === 201) {
                    const data = JSON.parse(xhr.responseText);
                   
                    seThumb(data?.url);
                     setFieldValue("thumbnail", data?.image);
                     setPageLoader(false)
                     SetThumLoader(0); 
                  } else {
                    const errorData = JSON.parse(xhr.responseText);
                    ToastServices.ErroToast(`Upload failed: `);
                  }
                };
      
                xhr.onerror = () => {
                  ToastServices.ErroToast(`Error uploading file: `);
                };
      
                xhr.send(formData);
              
            } else {
              ToastServices.ErroToast("Invalid aspect ratio.");
            }
          };
          img.onerror = () => {
            ToastServices.ErroToast("Error loading image.");
          };
          img.src = URL.createObjectURL(file);
        } else {
          ToastServices.ErroToast("File is too large.");
        }
      } else {
        ToastServices.ErroToast("Invalid file type.");
      }

      event.target.value = null;
    }
  };
  
  const handleRemoveFile = (setFieldValue) => {
    seThumb(null);
    setFieldValue("thumbnail", '');
    
    if (thumnailRef.current) {
      thumnailRef.current.value = null;
    }
  };
 const handleFileChangeBanner = async (event, setFieldValue,) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxFileSize = 50 * 1024 * 1024;
      const validFileExtensions = ["jpeg", "jpg", "png"];
      if (validFileTypes.includes(fileType) && validFileExtensions.includes(fileExtension)) {
        if (file.size <= maxFileSize) {
          const img = new Image();
          img.onload = async () => {
            const aspectRatio = img.width / img.height;
            const isValidAspectRatio = Math.abs(aspectRatio - (4 / 1)) < 0.01;

            if (isValidAspectRatio) {

                const formData = new FormData();
                formData.append("file", file);
      
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${config.apiUrl}/upload`, true);
      
                
                xhr.upload.onprogress = (event) => {
                  if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    setBannerLoader(percent);
                   
                  }
                };
      
                xhr.onload = async () => {
                  if (xhr.status === 201) {
                    const data = JSON.parse(xhr.responseText);
                 
                     setBannerImg(data?.url);
                     setPageLoader(false)
                     setFieldValue("banner", data?.image);
                     setBannerLoader(0); 
                  } else {
                    const errorData = JSON.parse(xhr.responseText);
                    ToastServices.ErroToast(`Upload failed: `);
                  }
                };
      
                xhr.onerror = () => {
                  ToastServices.ErroToast(`Error uploading file: `);
                };
      
                xhr.send(formData);
              
            } else {
              ToastServices.ErroToast("Invalid aspect ratio.");
            }
          };
          img.onerror = () => {
            ToastServices.ErroToast("Error loading image.");
          };
          img.src = URL.createObjectURL(file);
        } else {
          ToastServices.ErroToast("File is too large.");
        }
      } else {
        ToastServices.ErroToast("Invalid file type.");
      }

      event.target.value = null;
    }


  };

  const handleRemoveBanner = (setFieldValue) => {
    setBannerImg(null);
    setFieldValue("banner", '');
    
    if (BannerRef.current) {
      BannerRef.current.value = null;
    }
  };
 
   const postEvent = async (values) =>{

     const userData = {
        eventTitle: values?.eventTitle,
        eventDate: moment(values?.eventDate).format('YYYY-MM-DD'),
        location: values?.location,
        thumbnailImage: values?.thumbnail,
        bannerImage: values?.banner,
        eventDescription: values?.Description

     }
     
      const headers = {
        Authorization: `Bearer ${EncryptTOken}`,
        "Content-Type": "application/json",
      };

      
      try {
        const postResponse = await axios.post(`${config.apiUrl}/events/create`, userData, {
          headers,
        });
        if (postResponse?.data?.status === true) {
          setLoading(false);
          ToastServices.SucessToast(postResponse?.data?.message);
          navigate('/manage-events')
        } else if (postResponse?.data?.status === false) {
          ToastServices.ErroToast(postResponse?.data?.message);
          setLoading(false);
        } else if (postResponse?.data?.statusCode === 429) {
          setLoading(false);
          ToastServices.ErroToast("Something went wrong");
        } else if (postResponse?.data?.statusCode === 500) {
          ToastServices.ErroToast("Something went wrong");
          setLoading(false);
        } else if (postResponse?.data?.statusCode === 404) {
          ToastServices.ErroToast("Something went wrong");
          setLoading(false);
        }
      } catch (e) {
      }
   }

  



  return (
    <div>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >{`Create Event`}</Typography>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end mr-20">
          <div className="">
            <Link to={'/manage-events'}>
            <Button variant="outlined" className="outlined_btn">
              <ChevronLeftOutlinedIcon
                sx={{ mb: 0.2, mr: 0.5 }}
              />
              Back
            </Button>
            </Link>
          </div>
        </div>
      </Div>
      <Card sx={{ padding: "12px" }}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            
            onSubmit={(values) => {
              postEvent(values)
            }}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              setFieldTouched,
            }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Event Title <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      value={values.eventTitle}
                      name="eventTitle"
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      error={touched.eventTitle && Boolean(errors.eventTitle)}
                      helperText={touched.eventTitle && errors.eventTitle}
                      placeholder="Enter Event Title"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Event Date <span className="required">*</span>
                      </span>
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className= { touched.eventDate && errors.eventDate ? "form_new_style date_error"  : 'form_new_style'  } 
                        name="eventDate"
                        value={values.eventDate}
                        open={todateOpen}
                        onBlur={handleBlur}
                        slotProps={{
                          textField: { onClick: () => setTodateOpen(true) },
                        }}
                        onClose={() => setTodateOpen(false)}
                        onChange={(newDate, event) => {
                          setToDate(newDate);
                          setFieldValue("eventDate", newDate);
                        }}
                        
                        format="dd/MM/yyy"
                        renderInput={(params) => (
                          <TextField onBlur={handleBlur} {...params} />
                        )}
                      />
                      {touched.eventDate && errors.eventDate ? (
                        <Typography color="error">
                          <FormHelperText>
                            {errors.eventDate}
                          </FormHelperText>
                        </Typography>
                      ) : null}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Location <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                     
                      value={values.location}
                      name="location"
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      error={touched.location && Boolean(errors.location)}
                      helperText={touched.location && errors.location}
                      placeholder="Enter Location"
                    />
                  </Grid>
                 
                  <Grid item xs={12} md={12}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Description <span className="required">*</span>
                      </span>
                    </Typography>

                    <Field name="Description">
                      {({ field }) => (
                        <ReactQuill
                          theme="snow"
                          value={field.value}
                         onChange={(html) => {
                  setFieldValue(field.name, html);
                }}
                onBlur={() => {
                  setFieldTouched(field.name, true);
                }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: ['Inter'] }],
                              [{ size: ['small', false, 'large', 'huge'] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                              ],
                              ["link", ],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                          
                          ]}
                          style={{ height: "230px" }}
                        />
                      )}
                    </Field>

                    {touched.Description && errors.Description && (
                      <FormHelperText>{errors.Description}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6} md={6}>
                  <Typography className="login_label d-flex items-center g-10">
                  Thumbnail Image
                  <span className="required">*</span>
                  </Typography>
                  <div className="uploader_row">
                  <input type="file" ref={thumnailRef} style={{display:"none"}} 
                   accept="image/jpeg, image/png, image/jpg"
                   onChange={ (event) => handleFileChange(event, setFieldValue)}
                   />
                   {
                    !thumb && (
                    <div className="image_uploader" onClick={()=>{thumnailRef.current.click();}} >
                      <IoAdd fontSize={24} />
                      {
                                thumbLoader > 0 && (
                                  <div className="upload_loader" onClick={(event)=> event.stopPropagation()}>
                                  <span>{thumbLoader}%</span>
                               </div>
                                )
                              }
                    </div>
                    )
                   }
                    {
                      thumb && (

                        <div className="uploader_content">
                        <span className="close_icon" onClick={()=> handleRemoveFile(setFieldValue) }><IoClose /></span>
                        <img src={thumb} alt="Preview"/>

                      </div>
                      )
                    }
                  </div>

                  {touched.thumbnail && errors.thumbnail ? (
                    <FormHelperText >
                      {errors.thumbnail}
                    </FormHelperText>
                  ) : null}

                  <div className="upload_notes">
                    <p>
                      <span>Notes:</span> Image format should be jpg,png,jpeg
                      and size should not exceed 50mb
                    </p>
                    <p>
                    <span>Aspect Ratio:</span> 3:2
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className="login_label d-flex items-center g-10">
                  Banner Image
                  <span className="required">*</span>
                  </Typography>
                  
                  <div className="uploader_row">
                  <input type="file" ref={BannerRef} style={{display:"none"}} 
                   accept="image/jpeg, image/png, image/jpg"
                   onChange={ (event) => handleFileChangeBanner(event, setFieldValue)}
                   />

                   {
                    !bannerImg && (
                    <div className="image_uploader banner_img" onClick={()=>{BannerRef.current.click();}} >
                      <IoAdd fontSize={24} />
                      {
                                bannerLoader > 0 && (
                                  <div className="upload_loader " onClick={(event)=> event.stopPropagation()}>
                                  <span>{bannerLoader}%</span>
                               </div>
                                )
                              }
                    </div>
                    )
                   }
                    {
                      bannerImg && (
                        <div className="uploader_content banner_img">
                        <span className="close_icon" onClick={()=> handleRemoveBanner(setFieldValue) }><IoClose /></span>
                        <img src={bannerImg} alt="Preview"/>
                      </div>
                      )
                    }
                  </div>
                  
                  {touched.banner && errors.banner ? (
                    <FormHelperText >
                      {errors.banner}
                    </FormHelperText>
                  ) : null}

                  <div className="upload_notes">
                    <p>
                      <span>Notes:</span> Image format should be jpg,png,jpeg
                      and size should not exceed 50mb
                    </p>
                    <p>
                    <span>Aspect Ratio:</span> 4:1
                    </p>
                  </div>
                </Grid>

                  <Grid item md={12}>
                    <LoadingButton
                      loading={loading}
                      className="primary_btn w-365"
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {pageloader === true  ?   <LoaderComp /> : null}
    </div>
  );
};

export default CreateEvents;

