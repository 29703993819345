

export const JobBag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <path
        d="M25 7H18.875V5.25C18.875 4.78587 18.6906 4.34075 18.3624 4.01256C18.0342 3.68437 17.5891 3.5 17.125 3.5H11.875C11.4109 3.5 10.9658 3.68437 10.6376 4.01256C10.3094 4.34075 10.125 4.78587 10.125 5.25V7H4C3.53587 7 3.09075 7.18437 2.76256 7.51256C2.43437 7.84075 2.25 8.28587 2.25 8.75V22.75C2.25 23.2141 2.43437 23.6592 2.76256 23.9874C3.09075 24.3156 3.53587 24.5 4 24.5H25C25.4641 24.5 25.9092 24.3156 26.2374 23.9874C26.5656 23.6592 26.75 23.2141 26.75 22.75V8.75C26.75 8.28587 26.5656 7.84075 26.2374 7.51256C25.9092 7.18437 25.4641 7 25 7ZM11.875 5.25H17.125V7H11.875V5.25ZM25 8.75V16.625H4V8.75H25ZM4 22.75V18.375H25V22.75H4Z"
        fill="#131313"
        fill-opacity="0.75"
      />
      <path
        d="M13.625 15.75H15.375C15.6071 15.75 15.8296 15.6578 15.9937 15.4937C16.1578 15.3296 16.25 15.1071 16.25 14.875C16.25 14.6429 16.1578 14.4204 15.9937 14.2563C15.8296 14.0922 15.6071 14 15.375 14H13.625C13.3929 14 13.1704 14.0922 13.0063 14.2563C12.8422 14.4204 12.75 14.6429 12.75 14.875C12.75 15.1071 12.8422 15.3296 13.0063 15.4937C13.1704 15.6578 13.3929 15.75 13.625 15.75Z"
        fill="#131313"
        fill-opacity="0.75"
      />
    </svg>
  );
};

export const MapSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clip-path="url(#clip0_1016_216)">
        <path
          d="M14 14.875C15.933 14.875 17.5 13.308 17.5 11.375C17.5 9.442 15.933 7.875 14 7.875C12.067 7.875 10.5 9.442 10.5 11.375C10.5 13.308 12.067 14.875 14 14.875Z"
          stroke="#333333"
          stroke-opacity="0.75"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.75 11.375C22.75 19.25 14 25.375 14 25.375C14 25.375 5.25 19.25 5.25 11.375C5.25 9.05436 6.17187 6.82876 7.81282 5.18782C9.45376 3.54687 11.6794 2.625 14 2.625C16.3206 2.625 18.5462 3.54687 20.1872 5.18782C21.8281 6.82876 22.75 9.05436 22.75 11.375Z"
          stroke="#333333"
          stroke-opacity="0.75"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1016_216">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ClockSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
    >
      <g clip-path="url(#clip0_1016_208)">
        <path
          d="M12.9725 25.375C10.8877 25.375 8.84974 24.7079 7.11631 23.458C5.38287 22.2081 4.03183 20.4315 3.23402 18.353C2.43621 16.2745 2.22746 13.9874 2.63418 11.7809C3.0409 9.57432 4.04482 7.54749 5.51898 5.95667C6.99315 4.36584 8.87134 3.28248 10.9161 2.84357C12.9608 2.40467 15.0802 2.62993 17.0063 3.49088C18.9324 4.35182 20.5786 5.80978 21.7369 7.68039C22.8951 9.551 23.5133 11.7502 23.5133 14C23.5133 17.0168 22.4028 19.9101 20.426 22.0433C18.4492 24.1766 15.7681 25.375 12.9725 25.375ZM12.9725 4.37501C11.2084 4.37501 9.484 4.9395 8.01726 5.99711C6.55051 7.05472 5.40731 8.55794 4.73224 10.3167C4.05717 12.0754 3.88054 14.0107 4.22469 15.8778C4.56884 17.7448 5.41831 19.4598 6.66568 20.8059C7.91304 22.152 9.50229 23.0687 11.2324 23.4401C12.9626 23.8114 14.7559 23.6208 16.3857 22.8923C18.0155 22.1639 19.4084 20.9302 20.3885 19.3474C21.3685 17.7645 21.8916 15.9037 21.8916 14C21.8916 11.4473 20.952 8.99914 19.2793 7.1941C17.6066 5.38906 15.338 4.37501 12.9725 4.37501Z"
          fill="black"
          fill-opacity="0.75"
        />
        <path
          d="M17.4325 19.6875C17.3258 19.6882 17.22 19.6661 17.1213 19.6226C17.0225 19.579 16.9326 19.5148 16.8568 19.4337L12.3973 14.6212C12.3221 14.5395 12.2627 14.4425 12.2223 14.3359C12.182 14.2293 12.1615 14.1152 12.1621 14V7C12.1621 6.76794 12.2475 6.54538 12.3996 6.38128C12.5517 6.21719 12.7579 6.125 12.973 6.125C13.188 6.125 13.3942 6.21719 13.5463 6.38128C13.6984 6.54538 13.7838 6.76794 13.7838 7V13.6413L18.0082 18.1913C18.0842 18.2726 18.1446 18.3694 18.1857 18.476C18.2269 18.5826 18.2481 18.697 18.2481 18.8125C18.2481 18.928 18.2269 19.0424 18.1857 19.149C18.1446 19.2556 18.0842 19.3524 18.0082 19.4337C17.9325 19.5148 17.8426 19.579 17.7438 19.6226C17.645 19.6661 17.5393 19.6882 17.4325 19.6875Z"
          fill="black"
          fill-opacity="0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_1016_208">
          <rect width="25.9467" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DateSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M17.6752 5.42875C17.5765 3.78656 16.2052 2.5 14.5536 2.5H13.7496V1.875C13.7496 1.53 13.4696 1.25 13.1246 1.25C12.7796 1.25 12.4996 1.53 12.4996 1.875V2.5H7.49958V1.875C7.49958 1.53 7.21958 1.25 6.87458 1.25C6.52958 1.25 6.24958 1.53 6.24958 1.875V2.5H5.44552C3.79364 2.5 2.42239 3.78656 2.32395 5.42875C2.13864 8.5175 2.14208 11.6506 2.33427 14.7409C2.43177 16.3103 3.68895 17.5675 5.25833 17.665C6.83114 17.7628 8.4152 17.8116 9.99927 17.8116C11.583 17.8116 13.1674 17.7628 14.7402 17.665C16.3096 17.5675 17.5668 16.3103 17.6643 14.7409C17.8568 11.6522 17.8602 8.51937 17.6752 5.42875ZM16.4171 14.6634C16.3583 15.605 15.6043 16.3591 14.663 16.4175C11.5686 16.6097 8.43052 16.6097 5.33614 16.4175C4.39458 16.3587 3.64052 15.6047 3.58208 14.6634C3.43427 12.2875 3.40395 9.88656 3.48302 7.5H16.5165C16.5952 9.8875 16.5649 12.2884 16.4171 14.6634ZM6.87458 5C7.21958 5 7.49958 4.72 7.49958 4.375V3.75H12.4996V4.375C12.4996 4.72 12.7796 5 13.1246 5C13.4696 5 13.7496 4.72 13.7496 4.375V3.75H14.5536C15.5452 3.75 16.3683 4.52031 16.4274 5.50344C16.4421 5.75156 16.4483 6.00125 16.4605 6.25H3.53864C3.55114 6.00125 3.55708 5.75156 3.57177 5.50344C3.63083 4.52031 4.45364 3.75 5.44552 3.75H6.24958V4.375C6.24958 4.72 6.52958 5 6.87458 5Z" fill="#7A7A7A"/>
<path d="M6.875 11.25C7.39277 11.25 7.8125 10.8303 7.8125 10.3125C7.8125 9.79473 7.39277 9.375 6.875 9.375C6.35723 9.375 5.9375 9.79473 5.9375 10.3125C5.9375 10.8303 6.35723 11.25 6.875 11.25Z" fill="#7A7A7A"/>
<path d="M10 11.25C10.5178 11.25 10.9375 10.8303 10.9375 10.3125C10.9375 9.79473 10.5178 9.375 10 9.375C9.48223 9.375 9.0625 9.79473 9.0625 10.3125C9.0625 10.8303 9.48223 11.25 10 11.25Z" fill="#7A7A7A"/>
<path d="M6.875 14.375C7.39277 14.375 7.8125 13.9553 7.8125 13.4375C7.8125 12.9197 7.39277 12.5 6.875 12.5C6.35723 12.5 5.9375 12.9197 5.9375 13.4375C5.9375 13.9553 6.35723 14.375 6.875 14.375Z" fill="#7A7A7A"/>
<path d="M13.125 11.25C13.6428 11.25 14.0625 10.8303 14.0625 10.3125C14.0625 9.79473 13.6428 9.375 13.125 9.375C12.6072 9.375 12.1875 9.79473 12.1875 10.3125C12.1875 10.8303 12.6072 11.25 13.125 11.25Z" fill="#7A7A7A"/>
<path d="M13.125 14.375C13.6428 14.375 14.0625 13.9553 14.0625 13.4375C14.0625 12.9197 13.6428 12.5 13.125 12.5C12.6072 12.5 12.1875 12.9197 12.1875 13.4375C12.1875 13.9553 12.6072 14.375 13.125 14.375Z" fill="#7A7A7A"/>
<path d="M10 14.375C10.5178 14.375 10.9375 13.9553 10.9375 13.4375C10.9375 12.9197 10.5178 12.5 10 12.5C9.48223 12.5 9.0625 12.9197 9.0625 13.4375C9.0625 13.9553 9.48223 14.375 10 14.375Z" fill="#7A7A7A"/>
</svg>
  );
};
export const EventLocationSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_1086_1280"  maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
    <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_1086_1280)">
    <path d="M5.11588 10.7591L8.77907 15.6429C9.05734 16.0139 9.50071 16.2539 10.0001 16.2539C10.4996 16.2539 10.9429 16.0139 11.2212 15.6429L14.8844 10.7591C15.6489 9.73985 16.1018 8.47348 16.1018 7.10136C16.1018 3.73151 13.37 0.999669 10.0001 0.999669C6.63024 0.999669 3.89844 3.73151 3.89844 7.10136C3.89844 8.47348 4.35141 9.73985 5.11588 10.7591Z" stroke="#8C8D8E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5274 7.10104C11.5274 7.94349 10.8444 8.62646 10.002 8.62646C9.15953 8.62646 8.47656 7.94349 8.47656 7.10104C8.47656 6.25859 9.15953 5.57562 10.002 5.57562C10.8444 5.57562 11.5274 6.25859 11.5274 7.10104Z" stroke="#8C8D8E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.94922 19H13.0509" stroke="#8C8D8E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
  );
};


