import { LOGGED_IN, LOGOUT, UPDATE_TOKEN, GET_TOKEN } from "app/utils/constants/authActions";
import { store } from "../store";
import { userJwtData, setEmptyUser } from "./userAction";

export const loginAction = (data) => {
    userJwtData(data)
    store.dispatch({
        type: LOGGED_IN,
        payload: data
    })
}
export const UpdateToken = (data) => {
    store.dispatch({
        type: UPDATE_TOKEN,
        payload: data
    })
}

export const logoutAction = () => {
    sessionStorage.clear();
    setEmptyUser()
    store.dispatch({
        type: LOGOUT
    })
}

export const GetToken = (newToken) => ({
    type: GET_TOKEN,
    payload: newToken
})